/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { IUserIntegrationsList } from 'pages/Settings/Integrations/types/integrationTypes';
import { baseQueryWithReauth } from 'api/api.config';
import { updateAccessRights } from 'store/user/user.slice';

export const integrationsAPI = createApi({
	reducerPath: 'integrationsAPI',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['UserIntegrationsList', 'UserIntegration'],
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		fetchAllIntegrations: build.query({
			query: (detail = false) => ({
				url: `/integrations/services${detail ? '?detail=true' : ''}`,
			}),
		}),
		getAccessParamsByService: build.query({
			query: (service_name) => ({
				url: `/integrations/services/${service_name}/access_params`,
			}),
		}),
		getSettingsByService: build.query({
			query: (service_name) => ({
				url: `/integrations/services/${service_name}/settings`,
			}),
		}),
		getServiceDetail: build.query({
			query: (service_name) => ({
				url: `/integrations/services/${service_name}/detail`,
			}),
		}),
		getUserIntegrationsList: build.query<IUserIntegrationsList[], string | undefined>({
			query: (user_id) => ({
				url: `/integrations/${user_id}`,
			}),
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({ type: 'UserIntegrationsList' as const, id })),
							{ type: 'UserIntegrationsList' as const },
					  ]
					: [{ type: 'UserIntegrationsList' as const }],
		}),
		getUserIntegratinSettings: build.query({
			query: ({ user_id, integration_id }) => ({
				url: `/integrations/${user_id}/${integration_id}`,
			}),
			providesTags: (result, error, { integration_id }) => [{ type: 'UserIntegration', id: integration_id }],
		}),
		getIntegrationStatus: build.query({
			query: ({ user_id, integration_id }) => ({
				url: `/integrations/tasks/${user_id}?integration_id=${integration_id}&latest=true`,
			}),
		}),
		createUserIntegration: build.mutation({
			query: ({ user_id, integrationParams }) => ({
				url: `/integrations/${user_id}`,
				method: 'POST',
				body: integrationParams,
			}),
			transformErrorResponse: (response: { status: string | number }) => response.status,
			invalidatesTags: ['UserIntegrationsList'],
		}),
		updateUserIntegratinSettings: build.mutation({
			query: ({ user_id, integration_id, integrationSettings }) => ({
				url: `/integrations/${user_id}/${integration_id}`,
				method: 'PUT',
				body: integrationSettings,
			}),
			transformErrorResponse: (response: { status: string | number }) => response.status,
			invalidatesTags: (result, error, { integration_id }) => [{ type: 'UserIntegration', id: integration_id }],
		}),
		deleteUserIntegration: build.mutation({
			query: ({ user_id, integration_id }) => ({
				url: `/integrations/${user_id}/${integration_id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['UserIntegrationsList'],
		}),
		validateFiltrationRule: build.mutation({
			query: ({ user_id, jsonBody }) => ({
				url: `/integrations/${user_id}/validation/filtration_rules`,
				method: 'POST',
				body: jsonBody,
			}),
		}),
		createFiltrationRule: build.mutation({
			query: ({ user_id, integration_id, jsonBody }) => ({
				url: `/integrations/${user_id}/${integration_id}/filtration_rules`,
				method: 'POST',
				body: jsonBody,
			}),
		}),
		getUserTasks: build.query({
			query: ({ userId, filters }) => ({
				url: `/integrations/tasks/${userId}`,
				params: filters,
			}),
		}),
		getTaskStatus: build.query({
			query: ({ taskId }) => ({
				url: `/integrations/tasks/${taskId}`,
			}),
		}),
		cancelUserTask: build.mutation({
			query: ({ taskId }) => ({
				url: `/integrations/tasks/${taskId}`,
				method: 'DELETE',
			}),
			transformErrorResponse: (response: { status: string | number }) => response.status,
		}),
		getTaskLogs: build.query({
			query: ({ taskId, page }) => ({
				url: `/integrations/tasks/${taskId}/logs`,
				params: page,
			}),
		}),
		createUserTask: build.mutation({
			query: ({ user_id, taskData }) => ({
				url: `/integrations/tasks/${user_id}`,
				method: 'POST',
				body: taskData,
			}),
			transformErrorResponse: (response: { status: string | number }) => response.status,
		}),
		// Получаем список ключей для таба API Токены
		getUserApiKeys: build.query({
			query: ({ userId }) => ({
				url: `/user/${userId}/api_keys`,
			}),
		}),
		// Создаем новый ключ для таба API Токены
		createUserApiKey: build.mutation({
			query: ({ userId }) => ({
				url: `/user/${userId}/api_key`,
				method: 'POST',
			}),
		}),
		// Удаляем ключ для таба API Токены
		deleteUserApiKey: build.mutation({
			query: ({ userId, token }) => ({
				url: `/user/${userId}/api_key/${token}`,
				method: 'DELETE',
			}),
		}),
		// Получаем списока тегов для таба Переводы
		getUserTagTranslations: build.query({
			query: () => ({
				url: `/tag_translations/user`,
			}),
		}),
		// Получаем списока тегов для селекта для таба Переводы
		getUsertagNames: build.query({
			query: () => ({
				url: `/tag_names?only_form_integrations=true`,
			}),
		}),
		// Создаем новый перевод для таба Переводы
		createUserTagTranslation: build.mutation({
			query: ({ newTranslation }) => ({
				url: `/tag_translations`,
				method: 'POST',
				body: newTranslation,
			}),
		}),
		// Обновляем перевод для таба Переводы
		updateUserTagTranslation: build.mutation({
			query: ({ tagTranslationId, updatedTranslation }) => ({
				url: `/tag_translations/${tagTranslationId}`,
				method: 'PUT',
				body: updatedTranslation,
			}),
		}),
		// Удаляем перевод для таба Переводы
		deleteUserTagTranslation: build.mutation({
			query: ({ tagTranslationId }) => ({
				url: `/tag_translations/${tagTranslationId}`,
				method: 'DELETE',
			}),
		}),
	}),
});
