/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '../../api/api.config';
import { userSlice } from '../user/user.slice';
import { AuthResponseErrors } from '../user/user.types';
import { UrlsType } from 'pages/Auth/hooks/useAuth';

type AuthType = {
	access_token: 'string';
	token_type: 'string';
};

export const registerNewUser = createAsyncThunk(
	'auth/registerNewUser',
	async (authData: { name: string; email: string; phoneNumber: string }) => {
		const { name, email, phoneNumber } = authData;
		const setUserData = {
			fullname: name,
			email,
			phone_number: phoneNumber,
			url_base: `${window.location.protocol}//${window.location.host}`,
		};
		const response = await instance.post<string>('/user/register', JSON.stringify(setUserData), {
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
			},
		});
		return response.status;
	},
);

export const approveUserToken = createAsyncThunk('auth/approveToken', async (token: any) => {
	const tokenData = {
		token,
	};
	const response = await instance.post<string>('/user/approve', JSON.stringify(tokenData), {
		headers: {
			'Content-Type': 'application/json',
			accept: 'application/json',
		},
	});
	return response.status;
});

export const fetchAuthToken = createAsyncThunk(
	'auth/fetchAuthToken',
	async (authData: { username: string; password: string }, thunkAPI) => {
		try {
			const bodyFormData = new FormData();
			bodyFormData.append('username', authData.username);
			bodyFormData.append('password', authData.password);
			const response = await instance.post<AuthType>('token', bodyFormData, {
				headers: { 'Content-Security-Policy': 'block-all-mixed-content' },
			});
			localStorage.setItem(
				'localToken',
				JSON.stringify({
					localToken: response.data.access_token,
				}),
			);
			sessionStorage.setItem(
				'sessionToken',
				JSON.stringify({
					sessionToken: response.data.access_token,
				}),
			);
			thunkAPI.dispatch(userSlice.actions.setAuth('loading'));
			return response.data;
		} catch (error) {
			const status = (error as any).response?.status;
			if (status === AuthResponseErrors.FieldsRequired) {
				thunkAPI.dispatch(userSlice.actions.setError('Fields are required'));
			} else if (status === AuthResponseErrors.IncorrectData) {
				thunkAPI.dispatch(userSlice.actions.setError('Wrong login or password'));
			} else {
				thunkAPI.dispatch(
					userSlice.actions.setError('We are very sorry. The server is not responding. Try later. : ('),
				);
			}
		}
	},
);

export const removeAuthToken = createAsyncThunk('auth/removeAuthToken', async (_, { dispatch }) => {
	sessionStorage.removeItem('sessionToken');
	localStorage.removeItem('localToken');
	localStorage.removeItem('adminToken');
	dispatch(userSlice.actions.setAuth(false));
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async (email: string) => {
	const response = await instance.post<string>(
		'/user/password_reset',
		{ email },
		{
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
			},
		},
	);
	return response;
});

export const getUrlsToRegisterWith = createAsyncThunk('auth/getUrlsToRegisterWith', async () => {
	try {
		const response = await instance.get<UrlsType>('/open_id/list');
		return response.data;
	} catch (error) {
		throw new Error('error while fetch urls');
	}
});
