/* eslint-disable import/no-mutable-exports */
import { EnvsManager } from './utils/enviroments';

export let API_URL = '';
export let OLD_INTERFACE = '';
export let API_URL_WEBSOKET = '';

if (EnvsManager.isDev) {
	if (EnvsManager.isFeatureReports) {
		API_URL = 'https://api.reports.dev.imot.ru/';
		API_URL_WEBSOKET = 'wss://api.reports.dev.imot.ru/';
	} else {
		API_URL = 'https://api.stand.imot.io/';
		API_URL_WEBSOKET = 'wss://api.stand.imot.io/';
	}

	OLD_INTERFACE = 'https://imot-stand.pyzzle.ru/';
} else {
	if (EnvsManager.isPPR) {
		API_URL = 'https://api.ppr.imot.io/';
		API_URL_WEBSOKET = 'wss://api.ppr.imot.io/';
		OLD_INTERFACE = '';
        } else {
		API_URL = 'https://api.imot.io/';
		API_URL_WEBSOKET = 'wss://api.imot.io/';
		OLD_INTERFACE = 'https://test.imot.io/';
	}
}


if (window.location.host == '127.0.0.1:30103') {
    API_URL = 'http://127.0.0.1:30102/';
    API_URL_WEBSOKET = 'ws://127.0.0.1:30102/';
    OLD_INTERFACE = 'http://127.0.0.1:30101/';
};


// API_URL
// стенд - https://api.stand.imot.io/
// прод ❗❗❗БИКАРЕФУЛ❗❗❗ - https://api.imot.io/
//
// OLD_INTERFACE
// стенд - https://imot-stand.pyzzle.ru/
// прод - https://test.imot.io/
