import { translate } from 'localizations';
import { AccessRights } from 'store/user/user.types';
import { OptionType } from 'components/common/Selects/LabelSelect/LabelSelect';
import { useMemo } from 'react';
import { useAppSelector } from './redux';

/*
		isSingleCall      - действия для одного звонка или нет
		isSettingsActions - действия на странице настроек
		isHiddenTags      - переключение названия для скрытия/ показа скрытых серых тегов в звонке      
	*/

const useCallsActions = (
	accessRights: AccessRights | null,
	isSingleCall = false,
	isSettingsActions = false,
	isHiddenTags = true,
) => {
	const language = useAppSelector((state) => state.lang.language);

	const actionsTranslations: Record<string, OptionType> = {
		reset_manual: { value: 'reset_manual', label: translate('resetManual', language) },
		meta_info: { value: 'meta_info', label: translate('metaInfo', language) },
		swap_channels: { value: 'swap_channels', label: translate('swapChannels', language) },
		get_api_tags: { value: 'get_api_tags', label: translate('getApiTags', language) },
		apply_notify_rules: { value: 'apply_notify_rules', label: translate('applyNotifyRules', language) },
		apply_addressbook_tags: {
			value: 'apply_addressbook_tags',
			label: translate('applyAddressbookTags', language),
		},
		editNotificationRule: { value: 'editNotificationRule', label: translate('editNotificationRule', language) },
		stt: { value: 'stt', label: translate('stt', language) },
		analyze: { value: 'analyze', label: translate('applyAnalyze', language) },
		apply_gpt: { value: 'apply_gpt', label: translate('applyGPT', language) },
		tags_filter: { value: 'tags_filter', label: translate('tagsFilter', language) },
		delete: {
			value: 'delete',
			label: isSingleCall ? translate('deleteCall', language) : translate('deleteCalls', language),
		},
		hidden_tags_to_open: { value: 'hidden_tags', label: translate('showHiddenTags', language) },
		hidden_tags_to_close: { value: 'hidden_tags', label: translate('hideHiddenTags', language) },
	};

	const userActionsArray = useMemo((): OptionType[] => {
		const actions: OptionType[] = [];

		if (accessRights) {
			if (accessRights.retag && isSettingsActions) {
				actions.push(actionsTranslations['analyze']);
			}
			if (accessRights.gpt && !isSingleCall) {
				actions.push(actionsTranslations['apply_gpt']);
			}
			if (accessRights.restt) {
				actions.push(actionsTranslations['stt']);
			}
			if (accessRights.manual_tagging && isSingleCall) {
				actions.push(actionsTranslations['reset_manual']);
			}
			if (isSingleCall) {
				isHiddenTags
					? actions.push(actionsTranslations['hidden_tags_to_open'])
					: actions.push(actionsTranslations['hidden_tags_to_close']);
			}
			if (isSingleCall) {
				actions.push(actionsTranslations['meta_info']);
			}
			if (accessRights.retag) {
				actions.push(actionsTranslations['swap_channels']);
				actions.push(actionsTranslations['get_api_tags']);
			}
			if (accessRights.notify) {
				actions.push(actionsTranslations['apply_notify_rules']);
			}
			if (accessRights.address_book) {
				actions.push(actionsTranslations['apply_addressbook_tags']);
			}
			if (accessRights.notify && isSingleCall) {
				actions.push(actionsTranslations['editNotificationRule']);
			}
			if (accessRights.tags && !isSingleCall && !isSettingsActions) {
				actions.push(actionsTranslations['tags_filter']);
			}
			if (accessRights.delete_call) {
				actions.push(actionsTranslations['delete']);
			}
		}

		return actions as OptionType[];
	}, [accessRights, isSingleCall, isSettingsActions, isHiddenTags]);

	return { userActionsArray };
};

export default useCallsActions;
