/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';
import { CallTagType } from 'store/calls/calls.types';
import { translate } from 'localizations';
import { getGlobalStyles } from 'styles';
import { useSelector } from 'react-redux';
import { callsActions } from 'store/calls';
import { RootState } from 'store/store';
import { NewTagComponent } from 'shared/ui';
import { getFormattedTags, getTagTheme, sortFunctionByManual } from '../../CallBody/utils';
import { callTagsLimit } from '../../constants';
import s from '../../styles.module.scss';
import React from 'react';
import { useAppDispatch } from 'hooks/redux';
import { TagGroup } from 'widgets/ManualTagging/types';
import { TagsWithCount } from 'pages/Calls/types';

interface IOwnProps {
	language: string;
	tags: TagsWithCount[];
	callId: string | undefined;
	hasCallInfo: boolean;
	solo: boolean | undefined;
	expanded: boolean;
	accessRights: boolean | undefined;
	deleteTag?: ((id: string) => Promise<void>) | undefined;
	tagGroup: TagGroup | null;
	clickedTagId: string | null;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
	isHiddenTags: boolean;
}

const CallsTags: FC<IOwnProps> = ({
	tags,
	language,
	callId,
	hasCallInfo,
	solo,
	expanded,
	deleteTag,
	accessRights,
	tagGroup,
	clickedTagId,
	handleClickTagSettings,
	isHiddenTags,
}) => {
	const dispatch = useAppDispatch();
	const { setCallTagExpanded } = callsActions;
	const { grayTag } = getGlobalStyles();

	const sortedTags = useMemo(() => sortFunctionByManual(tags), [tags]);

	const { trimmedTags, reducedTags } = useMemo(
		() => getFormattedTags(sortedTags, callTagsLimit),
		[sortedTags, callTagsLimit],
	);

	const expandedCallTags = useSelector((state: RootState) => state.calls.expandedCallTags);
	const showAllTags = callId ? expandedCallTags?.[callId] || false : false;

	const tagsToDisplay = showAllTags ? sortedTags : trimmedTags;

	const handleShowMoreTags = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (callId) {
			dispatch(setCallTagExpanded({ callId, isExpanded: !showAllTags }));
		}
	};

	const stopPropagation = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};

	return (
		<div className={s.container} onClick={stopPropagation}>
			{tags?.length > 0 ? (
				tagsToDisplay
					.filter((tag) => solo || expanded || tag.tag.visible)
					.map((tag: { tag: CallTagType; count: number }) => {
						if (isHiddenTags && (solo || expanded) && !tag.tag.visible) return null;
						const callTag = tag?.tag;
						return (
							<NewTagComponent
								id={callTag.id}
								key={callTag.name + callTag.value}
								name={callTag.name}
								value={callTag.value ?? null}
								theme={getTagTheme(callTag, solo || expanded)}
								language={language}
								cursor="pointer"
								settings={(solo || expanded) && true}
								accessRights={accessRights}
								isActiveTag={clickedTagId === callTag.id && tagGroup === 'call'}
								handleClickTag={handleClickTagSettings}
								deleteTag={deleteTag}
								tagGroup="call"
							/>
						);
					})
			) : hasCallInfo ? (
				translate('noTags', language)
			) : (
				<div className={s.skeletonContainer}>
					<Skeleton className={s.skeleton} height={20} variant="text" />
					<Skeleton className={`${s.skeleton} ${s.skeletonWithMarginTop}`} height={20} variant="text" />
				</div>
			)}
			{reducedTags?.length > 0 && (
				<button className={grayTag} onClick={handleShowMoreTags}>
					{showAllTags
						? translate('tagHide', language)
						: `${translate('show_more', language)} (${tags?.length - callTagsLimit})`}
				</button>
			)}
		</div>
	);
};

export default React.memo(CallsTags);
