import { FC } from 'react';

interface PlaySvgProps {
	width?: string;
	height?: string;
	color?: string;
	props?: any;
}

const PlayIcon: FC<PlaySvgProps> = ({ width = 32, height = 32, color = 'white', props }) => (
	<svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g id="Icon / Outlined / CaretRight">
			<path
				id="Vector"
				d="M22.3687 15.4204L10.4687 5.15787C10.025 4.77662 9.375 5.12037 9.375 5.73599V26.261C9.375 26.8766 10.025 27.2204 10.4687 26.8391L22.3687 16.5766C22.7094 16.2829 22.7094 15.7141 22.3687 15.4204Z"
				fill={color}
			/>
		</g>
	</svg>
);

export default PlayIcon;
