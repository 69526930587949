import { FC } from 'react';
import { BlockBox } from 'components/common';
import { CallTagType } from 'store/calls/calls.types';
import { translate } from 'localizations';
import { CircularProgress } from '@mui/material';
import ManualTaggingHeader from '../components/ManualTaggingHeader';
import ManualTaggingBody from '../components/ManualTaggingBody';
import useManualTagging from '../hooks/useManualTagging';
import s from './styles.module.scss';
import { TagGroup } from '../types';

interface ManualTaggingProps {
	callId: string;
	language: string;
	tags: CallTagType[]; // список зеленых ручных тегов звонка
	isCallLoading: boolean;
	accessRights: boolean;
	tagGroup: TagGroup | null;
	clickedTagId: string | null;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
	isHiddenTags: boolean;
}

const ManualTagging: FC<ManualTaggingProps> = ({
	callId,
	language,
	tags,
	isCallLoading,
	accessRights,
	tagGroup,
	clickedTagId,
	handleClickTagSettings,
	isHiddenTags,
}) => {
	const { data, methods } = useManualTagging(callId, tags, language);
	const { isLoadingAddManual, tagsLocal, tagsListForSelect, disabledAddBtn } = data;
	const { addNewManualTag, createTagAsync, handleValidate, handleDeleteTag, resetNewEmptyTag } = methods;

	return (
		<BlockBox padding="16px" margin={'16px 0 0 0'} height="auto">
			{isCallLoading ? (
				<span className={s.loading}>
					<CircularProgress />
				</span>
			) : (
				<div className={s.manualGroup}>
					<ManualTaggingHeader
						label={translate('manualTagging', language)}
						labelTooltip={translate('manualTaggingAdd', language)}
						handlerAddManualTag={addNewManualTag}
						disabledAddBtn={disabledAddBtn}
					/>

					<ManualTaggingBody
						tags={tagsLocal}
						tagsList={tagsListForSelect}
						language={language}
						isLoadingAddTag={isLoadingAddManual}
						handleAddTag={createTagAsync}
						handleValidate={handleValidate}
						accessRights={accessRights}
						deleteTag={handleDeleteTag}
						resetNewEmptyTag={resetNewEmptyTag}
						tagGroup={tagGroup}
						clickedTagId={clickedTagId}
						handleClickTagSettings={handleClickTagSettings}
						isHiddenTags={isHiddenTags}
					/>
				</div>
			)}
		</BlockBox>
	);
};

export default ManualTagging;
