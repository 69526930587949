/* eslint-disable react/no-unstable-nested-components */
import { FC, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { CriteriasType } from 'store/search/search.types';
import { createTextSelectStyles, useMuiTextSelectStyles } from './AddFilterSelect.jss';
import CustomMenuList from './components/CustomMenuList';
import CustomSelectControl from './components/CustomSelectControl';

// TYPES BLOCK
export type SelectValueFormat = {
	value: any | null;
	label: string | null;
};

type TextSelectPropsType = {
	value: SelectValueFormat | null;
	options: SelectValueFormat[];

	iconPosition?: 'left' | 'right';
	icon?: any;

	customControl: any;

	menuPosition: 'left' | 'right';

	width?: string;
	height?: string;
	ifArrowColor?: string;

	name: string;

	setCriterias?: (keys: CriteriasType[]) => void;
	removeCriterias?: (keys: CriteriasType[]) => void;
	setCriteria: (key: CriteriasType) => void;
	removeCriteria: (key: CriteriasType) => void;
	activeCriterias: CriteriasType[];

	testId?: string;
};

const AddFilterSelect: FC<TextSelectPropsType> = ({
	value,
	options,
	icon,
	iconPosition,
	customControl,
	menuPosition,
	width,
	height,
	ifArrowColor,

	name,
	setCriterias,
	removeCriterias,
	removeCriteria,
	setCriteria,
	activeCriterias,
	testId,
}) => {
	const customSelectStyles = createTextSelectStyles({ width, menuPosition });
	const classes = useMuiTextSelectStyles({ arrowColor: ifArrowColor });

	// открыте и закрытие менюшки.
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	// поиск по критериям
	const [searchValue, setSearchValue] = useState('');

	const wrapperRef = useRef<HTMLDivElement>(null);

	// костылечек для загрытия менюшки.
	useEffect(() => {
		function handleDocumentClick(event: MouseEvent) {
			// Если кликнули вне контейнера - закрываем меню
			if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
				setIsMenuOpen(false);
			}
		}

		document.addEventListener('mousedown', handleDocumentClick);
		return () => {
			document.removeEventListener('mousedown', handleDocumentClick);
		};
	}, []);

	const MenuList = () => (
		<CustomMenuList
			ifArrowColor={ifArrowColor}
			activeCriterias={activeCriterias}
			removeCriteria={removeCriteria}
			setCriteria={setCriteria}
			searchValue={searchValue}
			onChangeSearchValue={setSearchValue}
		/>
	);

	const Control = () => (
		<CustomSelectControl
			setIsMenuOpen={(isOpen: any) => {
				setIsMenuOpen(isOpen);
			}}
			isMenuOpen={isMenuOpen}
			iconPosition={iconPosition}
			customControl={customControl}
			icon={icon}
			value={value}
			name={name}
			ifArrowColor={ifArrowColor}
		/>
	);

	const handleSelectChange = (event: any) => {
		setIsMenuOpen(false); // Закрываем меню после выбора опции
	};

	return (
		<div ref={wrapperRef} className={classes.selectBox} data-testid={testId || ''}>
			<Select
				styles={customSelectStyles}
				options={options}
				value={value}
				openMenuOnFocus
				menuIsOpen={isMenuOpen}
				isClearable
				closeMenuOnSelect={false}
				isSearchable={false}
				hideSelectedOptions={false}
				menuPlacement="auto"
				onChange={handleSelectChange}
				components={{
					IndicatorsContainer: () => null,
					Control,
					MenuList,
					Option: () => null,
				}}
			/>
		</div>
	);
};

export default AddFilterSelect;
