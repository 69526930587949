import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const convertDateToFormat = (dates: Dayjs[] | undefined) => {
	if (dates) {
		return [dates[0].startOf('day').utc(true).format(), dates[1].endOf('day').utc(true).format()];
	}
	return [null, null];
};
