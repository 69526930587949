import { FC, SVGProps } from 'react';

export const CheckSvg: FC<SVGProps<SVGSVGElement>> = ({ color, width, height, ...props }) => (
	<svg
		width={width || '18'}
		height={height || '18'}
		viewBox="0 0 26 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.5341 8.63348C19.8514 8.95078 19.8514 9.46523 19.5341 9.78253L11.9508 17.3658C11.6335 17.6831 11.1191 17.6831 10.8018 17.3658L6.46844 13.0325C6.15114 12.7152 6.15114 12.2008 6.46844 11.8835C6.78574 11.5662 7.30019 11.5662 7.61749 11.8835L11.3763 15.6422L18.3851 8.63348C18.7025 8.31618 19.2168 8.31618 19.5341 8.63348Z"
			fill={color || '#95DE64'}
		/>
	</svg>
);
