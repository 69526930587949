import { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { AdminBar } from 'components/common';

const WithAdminBar: FC = ({ children }) => {
	const { loggedUser, accessRights } = useAppSelector((state) => state.user);
	const hasAdminBar = loggedUser && (loggedUser.role === 'admin' || loggedUser.role === 'manager');
	const isAuth = useAppSelector((state) => state.user.isAuth);
	const [localIsAuth, setLocalIsAuth] = useState<boolean | string>(false);
	const [isPPR, setIsPPR] = useState(false);

	const users = useAppSelector((state) => state.user.users);
	const managerUsers = users?.filter((user) => user.role === 'user');

	useEffect(() => {
		setLocalIsAuth(isAuth);
	}, [isAuth]);

	useEffect(() => {
		setIsPPR(!accessRights?.add_user && managerUsers?.length === 1);
	}, [isAuth]);

	return (
		<>
			{hasAdminBar && localIsAuth && !isPPR && <AdminBar user={loggedUser} />}
			{children}
		</>
	);
};

export default WithAdminBar;
