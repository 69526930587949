/* теги и значения  -  'tag_name_value'
     список тегов  -  'tag_name_list'
     конструктор - 'search_items'
		 по звонку - 'call'
*/

/* дефолтные группировки для строк и столбцов */
export const DEFAULT_ROW_GROUPING_KEY = 'time';
export const DEFAULT_COLUMN_GROUPING_KEY = 'calls_count';

/* что не входит  по строкам */
export const excludeRowGroupings = new Set(['tag']);
/* что не входит  по столбцам */
export const excludeColumnGroupings = new Set(['tag', 'checklist_avg']);

/* высота и ширина селектов в отчете */
export const selectHeight = '36px';
export const selectWidth = '252px';

/* routes */
export const pathCreateReport = 'report/create';

export const pathReports = 'reports';

export const pathReport = 'report';

export const pathReportHash = 'report_hash';

export const pathHideParameters = 'hide_parameters';

/* ключи, которые не учитывать в таблице при создании колонок */
export const excludeColumnsKeys = new Set(['call_ids_hash', 'additional_params']);

export const excludeColumnsKeysForColumns = new Set(['additional_params']);

/* report limit */
export const reportColsLimit = 100;

/* row keys for table */
export const rowTableKeys = {
	rowSum: 'row_sum', // вошедшие
	rowAll: 'row_all', // всего
	rowMissed: 'row_missed', // не вошедшие
};

/* значения, которые не показываются в графиках */
export const excludeChartsKeys = new Set([
	'first_call_dt',
	'last_call_dt',
	'client_phones',
	'operator_phones',
	'from_services',
]);

/* стадии формирования отчета */
export const loadingStages = [
	'getting_call_list',
	'call_processing',
	'col_processing',
	'row_processing',
	'summary_processing',
	'intersection_of_summaries',
	'delete_empty_rows',
	'fetching_comments',
	'rows_normalization',
];

/* колонки с датой и временем */
export const dateTimeCells = new Set(['first_call_dt', 'last_call_dt']);

/* колонки, у которых нет сортировки */
export const noSorting = new Set(['client_phones', 'operator_phones', 'from_services']);

/* Список параметров процентов (для форматирования вида в таблице) */
export const setValueFormatterPercent = new Set([
	'view_checklist_answer_avg_percent_param',
	'view_checklist_avg_percent_param',
	'percent_from_total_count',
	'percent_from_all_calls_col',
	'percent_from_all_calls_row',
	'percent_from_sum_calls_row',
	'percent_from_sum_calls_col',
	'checklist_avg_percent',
	'client_talk_time_percentage',
	'operator_talk_time_percentage',
	'silence_duration_percentage',
]);

/* Порядок параметров Настройки таблицы */
/* Дополнительные - скрытые, свернутые по умолчанию  */

export const hiddenViewOptions = new Set([
	'show_col_calls_sum',
	'show_col_all_calls_sum',
	'show_col_calls_missed',
	'show_zero_grouping_rows',
	'show_zero_cols',
	'show_zero_grouping_cols',
]);
