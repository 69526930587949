import { FC, SVGProps } from 'react';

export const CloseSvg: FC<SVGProps<SVGSVGElement>> = ({ color, width, height, ...props }) => (
	<svg
		width={width || '18'}
		height={height || '18'}
		viewBox="0 0 26 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.1377 7.86066C18.5079 8.23086 18.5079 8.83107 18.1377 9.20128L9.2003 18.1387C8.8301 18.5089 8.22988 18.5089 7.85968 18.1387C7.48948 17.7685 7.48948 17.1683 7.85968 16.7981L16.7971 7.86066C17.1673 7.49046 17.7675 7.49046 18.1377 7.86066Z"
			fill={color || 'black'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.85968 7.86066C8.22988 7.49046 8.8301 7.49046 9.2003 7.86066L18.1377 16.7981C18.5079 17.1683 18.5079 17.7685 18.1377 18.1387C17.7675 18.5089 17.1673 18.5089 16.7971 18.1387L7.85968 9.20128C7.48948 8.83107 7.48948 8.23086 7.85968 7.86066Z"
			fill={color || 'black'}
		/>
	</svg>
);
