import { TagsWithCount, TagThemesTypes, TagTypeEnum } from 'pages/Calls/types';
import { FormattedTags } from '../types';
import { CallHiddenTagType, CallSttWordType, CallTagType } from 'store/calls/calls.types';

const delimiter = ' ';
export const getDateFromAbsTime = (time: string) => time.split(delimiter)[0];

export const getTimeFromAbsTime = (time: string) => time.split(delimiter)[1];

const getTimeUnits = (absTime: string, prevAbsTime: string) => ({
	prevDate: getDateFromAbsTime(prevAbsTime),
	prevTime: getTimeFromAbsTime(prevAbsTime),
	currentDate: getDateFromAbsTime(absTime),
	currentTime: getTimeFromAbsTime(absTime),
});

export const getComparedAbsTime = (absTime: string, prevAbsTime: string) => {
	const { prevDate, prevTime, currentDate, currentTime } = getTimeUnits(absTime, prevAbsTime);

	let newTime = '';

	if (prevDate !== currentDate) {
		newTime = currentDate;
	}

	if (prevTime !== currentTime) {
		newTime = `${newTime} ${currentTime}`;
	}

	return newTime;
};

export const getAbsTimeForDialog = (absTime: string, prevAbsTime: string, fragmentIndex: number) => {
	if (fragmentIndex === 0) return absTime;

	return getComparedAbsTime(absTime, prevAbsTime);
};

export const getFormattedTags = <T>(tags: T[], limit: number): FormattedTags<T> => ({
	trimmedTags: tags?.length > limit ? tags.slice(0, limit) : tags,
	reducedTags: tags?.length > limit ? tags.slice(limit) : [],
});

export const sortFunctionByManual = (tags: TagsWithCount[]) => {
	return tags.sort((a, b) => {
		const res1 = a.tag.tagType === TagTypeEnum.manual,
			res2 = b.tag.tagType === TagTypeEnum.manual;

		if (res1 > res2) return -1;
		if (res1 < res2) return 1;
		else return 0;
	});
};

export const getTagTheme = (tag: CallTagType, solo: boolean | undefined): TagThemesTypes => {
	if (solo && !tag.visible) return 'gray';
	if (tag.tagType === TagTypeEnum.manual) return 'green';
	return 'blue';
};

export const getHiddenTagTheme = (tag: CallHiddenTagType): TagThemesTypes => {
	if (tag.tagType === TagTypeEnum.manual) return 'green';
	else if (tag.matchData) return 'purple';
	return 'blue';
};

export const getWordsIndices = (matchData: string, words: CallSttWordType[], highlightIndexesSet: Set<number>) => {
	const matchWords = matchData.split(' ');

	const matchWordLength = matchWords.length;
	let result = [];
	let pointer = 0;
	let indices = [];

	for (let i = 0; i < words.length; i++) {
		const word = words[i].word;

		if (word === matchWords[pointer]) {
			indices.push(i);
			pointer++;

			if (pointer === matchWordLength) {
				pointer = 0;
				result.push(...indices);
			}
		} else if (pointer !== 0) {
			pointer = 0;
			indices = [];
		}
	}
	return new Set(
		result.filter((item) => {
			return highlightIndexesSet.has(item);
		}),
	);
};
