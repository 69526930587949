import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'api/api.config';

export const uploadServiceAPI = createApi({
	reducerPath: 'uploadServiceAPI',
	baseQuery: baseQueryWithReauth,
	endpoints: (build) => ({
		// получение загруженных файлов
		getUploadedFiles: build.query({
			query: ({ purgatorium }) => ({
				url: `/uploads?purgatorium=${purgatorium}`,
				method: 'GET',
			}),
		}),
		// удаление загруженного файла
		deleteUploadedFile: build.mutation({
			query: ({ purgatorium, remotePath }) => ({
				url: `upload?purgatorium=${purgatorium}&remote_path=${remotePath}`,
				method: 'DELETE',
			}),
		}),
		// создание коммуникаций
		createFiles: build.mutation({
			query: (body) => ({
				url: `call/via_s3/`,
				method: 'POST',
				body,
			}),
		}),
	}),
});
