import { UniversalInput } from 'shared/ui';
import { WidgetProps } from '@rjsf/utils';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import s from './styles.module.scss';
import { useValidateStt } from 'widgets/STT/hooks/useValidateStt';

type InputType = 'string' | 'number' | 'boolean' | 'object' | 'array';

const CustomTextWidget = (props: WidgetProps) => {
	const { language } = useAppSelector((state) => state.lang);
	const integer = props.schema.type === 'integer';

	const { validateInputNumber, validateInputNumberValue } = useValidateStt(language);

	const min = integer && validateInputNumber ? validateInputNumber(props.name, 'min') : undefined;
	const max = integer && validateInputNumber ? validateInputNumber(props.name, 'max') : undefined;

	return (
		<div style={{ marginBottom: '16px' }}>
			<UniversalInput
				min={min}
				max={max}
				name={props.name}
				width="250px"
				testId={props.name}
				inputStyle="outlined"
				tooltipTheme="dark"
				tooltipPlacement="right"
				value={props.value}
				label={translate(props.name, language)}
				classNameLabel={s.label}
				tooltipTitle={translate(`${props.name}_tooltip`, language) ?? null}
				inputType={integer ? 'number' : (props.schema.type as InputType)}
				handleChange={(event) => {
					const value = event.target.value;
					if (validateInputNumberValue && integer) {
						if (validateInputNumberValue?.(value)) {
							props.onChange(value);
						}
					} else {
						props.onChange(value);
					}
				}}
			/>
		</div>
	);
};
export default CustomTextWidget;
