import { FC } from 'react';
import { InfoCircle } from 'shared/ui';

import cls from './styles.module.scss';

interface ErrorDataTableProps {
	firstLineText: string;
	secondLineText?: string;
	className?: string;
}

const ErrorTableDataComponent: FC<ErrorDataTableProps> = ({ firstLineText, secondLineText, className }) => {
	return (
		<div className={`${className}? ${className} ${cls.root} : ${cls.root}`}>
			<div className={cls.icon}>
				<InfoCircle fill="#F5222D" />
			</div>
			<div className={cls.textBlock}>
				<span className={cls.firstLine}>{firstLineText}</span>
				<span className={cls.secondLine}>{secondLineText}</span>
			</div>
		</div>
	);
};

export default ErrorTableDataComponent;
