import { CallInfoType, CallTagType } from 'store/calls/calls.types';
import { AccessRights, UserType } from 'store/user/user.types';
import { MetaTags } from './CallsPage';

export interface CommonPropsType {
	callInfo: CallInfoType | null;
	expanded: boolean;
	loadCheckLists(callId: string): void;
	language: string;
	userInfo: UserType | null;
	isAuth: boolean | 'loading';
	solo?: boolean;
	isLoading?: boolean;
	showOpenLink?: boolean;
	metaTags?: MetaTags | undefined;
	accessRights: AccessRights | null;
}

export const enum TagTypeEnum {
	manual = 'manual',
}

export type TagThemesTypes = 'gray' | 'blue' | 'purple' | 'orange' | 'lightBlue' | 'green';

export type TagsWithCount = { tag: CallTagType; count: number };
