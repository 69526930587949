import { useRef, useState } from 'react';
import { CallType } from 'store/calls/calls.types';

const useVideoPlayer = (currentCall: CallType | null | false) => {
	const videoPlayerRef = useRef<any>(currentCall ? null : '');
	const [playingVideo, setPlayingVideo] = useState(false);
	const [pipVideo, setPipVideo] = useState(false);

	const rewindVideoHandler = (wordBegin: number) => {
		setPlayingVideo(true);
		videoPlayerRef.current.seekTo(wordBegin / 1000 + 0.01);
	};

	const pauseVideoHandler = () => {
		setPlayingVideo(false);
	};

	const playVideoHandler = () => {
		setPlayingVideo(true);
	};

	const enablePipVideo = () => {
		setPipVideo(true);
	};

	const disablePipVideo = () => {
		setPipVideo(false);
	};

	return {
		data: {
			videoPlayerRef,
			playingVideo,
			pipVideo,
		},
		methods: {
			rewindVideoHandler,
			pauseVideoHandler,
			playVideoHandler,
			enablePipVideo,
			disablePipVideo,
		},
	};
};

export default useVideoPlayer;
