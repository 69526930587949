import { memo } from 'react';
import 'rsuite/dist/rsuite.min.css';
import { WithAdminBar, WithAuthContent, WithMainLoader } from 'containers';

const Routes = memo(() => {
	return (
		<WithMainLoader>
			<WithAdminBar>
				<WithAuthContent />
			</WithAdminBar>
		</WithMainLoader>
	);
});

export default Routes;
