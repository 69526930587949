import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'api/api.config';
import { SearchFilterHashResponse } from 'store/search/namespaces/responses';

export const dealsAPI = createApi({
	reducerPath: 'dealsAPI',
	baseQuery: baseQueryWithReauth,
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		// Возвращает список сделок с описанием
		getDeals: build.query({
			query: ({
				filters = [],
				page = 1,
				limit = 50,
			}: { filters?: any[]; page?: number; limit?: number } = {}) => {
				// Есть ли хотя бы один фильтр передан, то возвращаем URL без параметров skip и limit.
				// Так работет поиск по фильтрам
				if (filters.length > 0) {
					return {
						url: `/deals/search?return_deal_info=true`,
						method: 'POST',
						body: filters,
					};
				} else {
					// Если фильтров нет, возвращаем URL с параметрами skip и limit.
					// Так работает пагинация
					const skip = (page - 1) * limit;
					return {
						url: `/deals/search?return_deal_info=true&skip=${skip}&limit=${limit}`,
						method: 'POST',
						body: filters,
					};
				}
			},
		}),
		getSearchHash: build.query<SearchFilterHashResponse, string>({
			query: (hash) => `/search_filter_hash/${hash}`,
		}),
	}),
});
