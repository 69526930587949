import { FC, SVGProps } from 'react';

const SimplePencil: FC<SVGProps<SVGSVGElement>> = ({ color, width, height, ...props }) => (
	<svg
		width={width || '24'}
		height={height || '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.6893 3.00184C14.9706 2.72053 15.3522 2.5625 15.75 2.5625C16.1478 2.5625 16.5294 2.72053 16.8107 3.00184L21 7.19118C21.2813 7.47249 21.4393 7.85402 21.4393 8.25184C21.4393 8.64966 21.2813 9.03119 21 9.3125L9.53033 20.7822C9.38968 20.9228 9.19891 21.0018 9 21.0018H4.5C4.10218 21.0018 3.72065 20.8438 3.43934 20.5625C3.15804 20.2812 3 19.8997 3 19.5018V15.3125C3 15.1155 3.0388 14.9205 3.11418 14.7385C3.18956 14.5565 3.30005 14.3911 3.43934 14.2518L14.6893 3.00184ZM19.9393 8.25184L15.75 4.0625L4.5 15.3125L3.97563 14.7881L4.5 15.3125L4.5 19.5018H8.68934L19.9393 8.25184Z"
			fill={color || '#9254DE'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.2197 5.46967C12.5126 5.17678 12.9874 5.17678 13.2803 5.46967L18.5303 10.7197C18.8232 11.0126 18.8232 11.4874 18.5303 11.7803C18.2374 12.0732 17.7626 12.0732 17.4697 11.7803L12.2197 6.53033C11.9268 6.23744 11.9268 5.76256 12.2197 5.46967Z"
			fill={color || '#9254DE'}
		/>
	</svg>
);

export default SimplePencil;
