import { useAppSelector } from 'hooks/redux';
import { Redirect } from 'react-router-dom';
import { selectActiveUser } from 'store/user/selectors';

const withProps = (Component: any) => () => {
	const accessRights = useAppSelector((state) => state.user.accessRights);
	const user = useAppSelector(selectActiveUser);

	if (!accessRights) {
		return <Redirect to="*" />;
	}

	return <Component accessRights={accessRights} user={user} />;
};

export default withProps;
