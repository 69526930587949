import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { callsActions } from 'store/calls';
import { CallTagType } from 'store/calls/calls.types';
import { getUrlForDev } from 'utils/url';
import { IconButton } from 'shared/ui';
import { Skeleton } from '@mui/material';
import { Share } from 'components/Icons/Share';
import { translate } from 'localizations';
import { useCurrentUser } from 'hooks';
import { ISearchedWord } from '../components/TagsBlock/TagsBlock';
import { CommonPropsType, TagsWithCount } from '../types';

type useCallsProps = CommonPropsType;

export interface TagWithValue {
	id: string;
	name: string;
	value: string;
	visible: boolean;
}

interface MetaTagsType {
	clientTags: CallTagType[] | undefined;
	operatorTags: CallTagType[] | undefined;
}

export const useCalls = (props: useCallsProps) => {
	const { callInfo, expanded, loadCheckLists, language, userInfo, isAuth, solo, isLoading, accessRights } = props;

	const dispatch = useDispatch();
	const host = window.origin;

	const currentUser = useCurrentUser();

	useEffect(() => {
		if (expanded && callInfo?.id) {
			loadCheckLists(callInfo?.id);
		}
	}, [callInfo?.id, expanded, loadCheckLists]);

	const scroll = useCallback(async (currentTarget: any) => {
		if (currentTarget) {
			const topValue = window.pageYOffset + currentTarget.getBoundingClientRect().top;
			await window.scrollTo({
				top: topValue,
				behavior: 'smooth',
			});
		}
	}, []);

	async function openLinkInNewTab() {
		if (callInfo) {
			const hrefWithEnv = getUrlForDev(`${language}/${userInfo?.id}/call?id=${callInfo.id}`);
			const newHref = `${host}${hrefWithEnv}`;

			window.open(newHref, '_blank');
		}
	}

	const accordionRef = useRef<any | null>(null);
	const accordionSummary = useRef<any | null>(null);
	const audioDivRef = useRef<any | null>(null);
	const tagsDivRef = useRef<any | null>(null);
	const paramsDivRef = useRef<any | null>(null);

	const onClick = useCallback(
		async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
			if (!callInfo) {
				return;
			}

			const selected = window.getSelection();
			const selectionText = selected?.toString();
			if (selectionText) {
				return;
			}

			if (!solo) {
				if (!expanded && callInfo) {
					// сборс переведенного текста
					batch(() => {
						dispatch(callsActions.setCallTranslated(null));
						dispatch(callsActions.setExpanded({ id: callInfo.id }));
					});

					scroll(e.target).then();
				} else if ((e.target as HTMLElement).tagName !== 'path') {
					dispatch(callsActions.setExpanded({ id: null }));
				}

				if (accordionRef.current && (e.target as HTMLElement).tagName !== 'path') {
					await window.scrollTo({
						top: window.scrollY + accordionRef.current.getBoundingClientRect().top,
						behavior: 'auto',
					});
				}
			}
		},
		[callInfo, solo, expanded, scroll],
	);

	const getOpenLinkButton = () => {
		if (!isAuth) return null;
		if (solo) return null;

		return isAuth && !isLoading ? (
			<IconButton
				margin="16px 0 0 8px"
				backgroundColor="#F8FAFC"
				icon={<Share />}
				onClick={openLinkInNewTab}
				testId="call_share"
				tooltipTitle={translate('tooltipAction', language)}
			/>
		) : (
			<Skeleton width={32} height={52} variant="text" style={{ margin: '5px 0 0 8px' }} />
		);
	};

	const [currentFragment, setCurrentFragment] = useState<CallTagType | ISearchedWord | null>(null);

	const setCurrentFragmentHandle = useCallback((fragment: CallTagType | ISearchedWord | null): void => {
		setCurrentFragment(fragment);
	}, []);

	const [metaTags, setMetaTags] = useState<Record<string, TagsWithCount[]>>();

	const getMetaTags = () => {
		if (currentUser) {
			const { clientTags, operatorTags } = callInfo || {};
			const partialUserData: Record<string, TagsWithCount[]> = {
				clientTags: [],
				operatorTags: [],
			};

			if (clientTags) {
				clientTags.forEach((clientTag) => {
					const existingTags = partialUserData.clientTags.find((tag) => {
						return tag.tag.name === clientTag.name && tag.tag.value === clientTag.value;
					});
					if (existingTags) {
						existingTags.count += 1;
					} else {
						partialUserData.clientTags.push({ tag: clientTag, count: 1 });
					}
				});
			}
			if (operatorTags) {
				operatorTags.forEach((operatorTag) => {
					const existingTags = partialUserData.operatorTags.find((tag) => {
						return tag.tag.name === operatorTag.name && tag.tag.value === operatorTag.value;
					});
					if (existingTags) {
						existingTags.count += 1;
					} else {
						partialUserData.operatorTags.push({ tag: operatorTag, count: 1 });
					}
				});
			}

			setMetaTags(partialUserData);
		}
	};

	useEffect(() => {
		if (currentUser && !['admin', 'manager'].includes(currentUser.role)) getMetaTags();
	}, [currentUser]);

	// разделяет теги на теги и фрагменты, и теги сделки
	const tagsAndFragmentsSeparator = () => {
		const tags: TagsWithCount[] = [];
		const dealTags: TagsWithCount[] = [];
		const fragments: TagsWithCount[] = [];
		const searchedWords = callInfo?.textSearchResult;

		if (callInfo?.fragmentTags) {
			callInfo.fragmentTags.forEach((currentFragmentTag) => {
				const existingFragment = fragments.find(
					(fragment) =>
						fragment.tag.name === currentFragmentTag.name &&
						fragment.tag.value === currentFragmentTag.value,
				);

				if (existingFragment) {
					existingFragment.count += 1;
				} else {
					fragments.push({ tag: currentFragmentTag, count: 1 });
				}
			});
		}

		// tags
		if (callInfo?.callTags) {
			callInfo?.callTags.forEach((currentCallTag) => {
				const existingTags = tags.find((tag) => {
					return tag.tag.name === currentCallTag.name && tag.tag.value === currentCallTag.value;
				});
				if (existingTags) {
					existingTags.count += 1;
				} else {
					tags.push({ tag: currentCallTag, count: 1 });
				}
			});
		}

		// deal tags
		if (callInfo?.dealTags) {
			callInfo?.dealTags.forEach((currentDealTag) => {
				const existingTags = dealTags.find((tag) => {
					return tag.tag.name === currentDealTag.name && tag.tag.value === currentDealTag.value;
				});
				if (existingTags) {
					existingTags.count += 1;
				} else {
					dealTags.push({ tag: currentDealTag, count: 1 });
				}
			});
		}
		return { tags, fragments, dealTags, searchedWords };
	};

	const tagsAndFragmentsArray = useMemo(() => tagsAndFragmentsSeparator(), [callInfo]);

	useEffect(() => {
		function onResize(): void {
			if (accordionSummary.current && audioDivRef.current && tagsDivRef.current && paramsDivRef.current) {
				tagsDivRef.current.style.top = `${accordionSummary.current.offsetHeight + 76}px`;
				paramsDivRef.current.style.top = `${accordionSummary.current.offsetHeight + 76}px`;
			}
		}

		onResize();
		window.addEventListener('resize', onResize);
		return (): void => {
			window.removeEventListener('resize', onResize);
		};
	});

	const declinationArray: string[] = [
		translate('score_1', language),
		translate('score_24', language),
		translate('score_many', language),
	];

	const stopPropagation = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};
	// кнопка в действиях звонка чтобы скрывать/показывать скрытые серые теги
	const [isHiddenTags, setIsHiddenTags] = useState(true);
	const handleHiddenTags = () => {
		setIsHiddenTags(!isHiddenTags);
	};

	const methods = useMemo(
		() => ({ setCurrentFragmentHandle, onClick, stopPropagation, getOpenLinkButton, handleHiddenTags }),
		[setCurrentFragmentHandle, onClick, stopPropagation, getOpenLinkButton, handleHiddenTags],
	);

	const data = useMemo(
		() => ({
			metaTags,
			tagsAndFragmentsArray,
			accordionRef,
			accordionSummary,
			declinationArray,
			currentFragment,
			audioDivRef,
			tagsDivRef,
			paramsDivRef,
			hasAccessToPhone: accessRights?.phone_number,
			hasAccessToDuration: callInfo?.communicationType === 'call' && accessRights?.phone_number,
			hasAccessToTags: accessRights?.tags,
			isHiddenTags,
		}),
		[
			callInfo,
			metaTags,
			tagsAndFragmentsArray,
			accordionRef,
			accordionSummary,
			declinationArray,
			currentFragment,
			audioDivRef,
			tagsDivRef,
			paramsDivRef,
			callInfo?.communicationType,
			accessRights?.phone_number,
			accessRights?.tags,
			isHiddenTags,
		],
	);

	return { methods, data };
};
