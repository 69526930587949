import { FC } from 'react';
import Form, { IChangeEvent } from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { UiSchema, RegistryWidgetsType } from '@rjsf/utils';
import { CustomCheckboxWidget, CustomSelectWidget, CustomTextWidget } from './components';
import { normalizeFormData } from './utils/utils';

// динамическая форма из JSON schema

interface DynamicFormProps {
	schema: any;
	hideLegend?: boolean; // скрывать легенду формы
	hideSubmit?: boolean; // скрывать кнопку submit
	styles?: Record<string, string>;
	onChange: (data: any, id: string | undefined) => void;
}

const DynamicForm: FC<DynamicFormProps> = ({ schema, hideLegend = true, hideSubmit = true, styles, onChange }) => {
	const widgets: RegistryWidgetsType = {
		CheckboxWidget: CustomCheckboxWidget,
		TextWidget: CustomTextWidget,
		SelectWidget: CustomSelectWidget,
	};

	const uiSchema: UiSchema = {
		// скрываем кнопку submit
		'ui:submitButtonOptions': { norender: hideSubmit },
		// скрываем легенду таблицы
		'ui:options': { label: !hideLegend },
		// задаем класс
		'ui:classNames': 'customForm',
		//  скрываем label у всего (чекбоксы и тп)
		'ui:globalOptions': { label: false },
	};

	return (
		<div style={{ ...styles }}>
			<Form
				uiSchema={uiSchema}
				schema={schema}
				widgets={widgets}
				validator={validator}
				onChange={(data: IChangeEvent<any, any, any>, id?: string | undefined) => {
					const fixedData = normalizeFormData(data.formData);
					onChange(fixedData, id);
				}}
				onSubmit={() => console.log('submitted')}
				onError={() => console.log('errors')}
			/>
		</div>
	);
};

export default DynamicForm;
