import { ReportInitialStateType, ViewOptionsRowsOrColumns } from './reports.types';

const calls = {
	call_ids_hash: '',
	additional_params: [],
};

const diffCalls = {
	calls_count: 0,
	calls_seconds: 0,
	percent_from_total_count: 0,
	client_talk_time: 0,
	client_talk_time_percentage: 0,
	operator_talk_time: 0,
	operator_talk_time_percentage: 0,
	silence_duration: 0,
	silence_duration_percentage: 0,
	tags_count: 0,
};

export const ViewOptionsGlobal = {
	show_table: true,
	show_chart: false,
	show_diff_report: false,
	show_zero_rows: true,
	show_zero_cols: true,
	show_zero_grouping_rows: true,
	show_zero_grouping_cols: true,
	show_row_calls_sum: true,
	show_col_calls_sum: true,
	show_row_all_calls_sum: false,
	show_col_all_calls_sum: false,
	show_row_calls_missed: false,
	show_col_calls_missed: false,
};

export const ViewOptionsGlobalWithCharts = {
	...ViewOptionsGlobal,
	chart_options: {
		by_sub_column: 'calls_count',
		chart_type: 'histogram',
	},
};

export const ViewOptionsRowsOrColumnsObj: ViewOptionsRowsOrColumns = {
	show_calls_count: true,
	show_minutes: false,
	show_avg_minutes: false,
	show_percentage: false,
	show_operator_time: false,
	show_percentage_from_all_calls_row: false,
	show_percentage_from_all_calls_col: false,
	show_percentage_from_sum_calls_row: false,
	show_percentage_from_sum_calls_col: false,
	show_client_time: false,
	show_silence_time: false,
	show_operator_time_percentage: false,
	show_client_time_percentage: false,
	show_silence_time_percentage: false,
	show_call_dt: false,
	show_first_call_dt: false,
	show_last_call_dt: false,
	show_tags_count: false,
	show_tags_avg_count: false,
	show_deals: false,
	show_checklist_average: false,
	show_checklist_average_percent: false,
	show_client_phones: false,
	show_operator_phones: false,
	show_points: false,
	show_max_points: false,
	show_from_services: false,
	additional_params: [],
};

export const initialState: ReportInitialStateType = {
	allReports: [],
	currentSavedReport: null,
	isSavedReport: false,
	isReportByHash: false,
	isReportById: false,
	isReportCollapsed: false,
	isReportTooBig: false,
	callReport: {
		report: {
			cols: [
				{
					group_id: 0,
					col_name: '',
				},
			],
			cols_info: {
				any: {
					any: {
						col_all_calls: calls,
						col_missed_calls: calls,
						col_sum_calls: calls,
					},
				},
			},
			filtered_calls_count: 0,
			row_group_header: '',
			rows: [],
			total_calls_count: 0,
			values: {
				any: {
					col_groups: {
						any: {
							any: calls,
						},
					},
					row_info: {
						row_all_calls: calls,
						row_missed_calls: calls,
						row_sum_calls: calls,
					},
				},
			},
			intersection_of_summaries: null,
		},
		report_hash: '',
		report_parameters_hash: '',
	},

	reportWebSocket: null,

	diffReport: {
		cols_info: {
			any: {
				any: {
					col_all_calls: diffCalls,
					col_missed_calls: diffCalls,
					col_sum_calls: diffCalls,
				},
			},
		},
		rows: {
			any: {
				col_groups: {
					any: {
						any: diffCalls,
					},
				},
				row_info: {
					row_all_calls: diffCalls,
					row_missed_calls: diffCalls,
					row_sum_calls: diffCalls,
				},
			},
		},
	},
	diffReportIsLoaded: false,
	isDiffReportHide: false,
	// строк и колонок
	view_options: ViewOptionsRowsOrColumnsObj,
	activeReport: {
		report_name: '',
		report_type: 'calls',
		period: 'today',
		from_dt: null,
		to_dt: null,
		// @ts-ignore
		view_options: ViewOptionsGlobalWithCharts,
		rows_group_by: [],
		cols_group_by: [],
		call_search_items: [],
	},
	additionalRows: [],

	date: [null, null],
	selectors: {
		cols_groupings: [],
		groupings_by_time: [],
		report_types: [],
		rows_groupings: [],
		view_additional_params: [],
	},

	tagNames: [],
	numTagsNames: [],
	tagNamesWithValues: [],
	tagColumnValues: [],
	tagColumnsAddValues: [],

	tableRows: [],
	tableColumns: [],
	tableAllRows: [],

	isResetParameters: false,
	isLoading: false,
	reportLoadingIsStarted: false,
	loadingReport: {
		status: '',
		stage_name: '',
		stage_idx: 0,
		done: 0,
		total: 0,
		done_part: 0,
	},

	isValidTagList: false,
};

export default initialState;
