import { FC, SVGProps } from 'react';

interface TotopIconProps extends SVGProps<SVGSVGElement> {}

const TotopIcon: FC<TotopIconProps> = (props) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M16 4.375C9.58125 4.375 4.375 9.58125 4.375 16C4.375 22.4188 9.58125 27.625 16 27.625C22.4188 27.625 27.625 22.4188 27.625 16C27.625 9.58125 22.4188 4.375 16 4.375ZM21.5625 19.3438H20.0969C19.7781 19.3438 19.475 19.1906 19.2875 18.9312L16 14.3875L12.7125 18.9312C12.525 19.1906 12.225 19.3438 11.9031 19.3438H10.4375C10.2344 19.3438 10.1156 19.1125 10.2344 18.9469L15.7969 11.2594C15.8969 11.1219 16.1 11.1219 16.2 11.2594L21.7625 18.9469C21.8844 19.1125 21.7656 19.3438 21.5625 19.3438Z"
			fill="#F9F0FF"
		/>
		<path
			d="M16 2C8.26875 2 2 8.26875 2 16C2 23.7313 8.26875 30 16 30C23.7313 30 30 23.7313 30 16C30 8.26875 23.7313 2 16 2ZM16 27.625C9.58125 27.625 4.375 22.4188 4.375 16C4.375 9.58125 9.58125 4.375 16 4.375C22.4188 4.375 27.625 9.58125 27.625 16C27.625 22.4188 22.4188 27.625 16 27.625Z"
			fill="#722ED1"
		/>
		<path
			d="M16.199 11.2594C16.1759 11.2275 16.1457 11.2015 16.1106 11.1835C16.0756 11.1656 16.0368 11.1562 15.9974 11.1562C15.958 11.1562 15.9192 11.1656 15.8842 11.1835C15.8491 11.2015 15.8188 11.2275 15.7958 11.2594L10.2333 18.9469C10.1146 19.1125 10.2333 19.3438 10.4364 19.3438H11.9021C12.2239 19.3438 12.5239 19.1907 12.7114 18.9313L15.999 14.3876L19.2865 18.9313C19.474 19.1907 19.7771 19.3438 20.0958 19.3438H21.5615C21.7646 19.3438 21.8833 19.1125 21.7615 18.9469L16.199 11.2594Z"
			fill="#722ED1"
		/>
	</svg>
);

export default TotopIcon;
