import { useEffect, useRef, useState } from 'react';

export const useDialogItemsSelector = (ref: any, open: boolean): [() => void] => {
	const anchorNode = useRef<Node | null | undefined>(null);
	const focusNode = useRef<Node | null | undefined>(null);
	const [anchorOffset, setAnchorOffset] = useState<number | undefined>();
	const [focusOffset, setFocusOffset] = useState<number | undefined>();

	const selector = (): void => {
		const test = window.getSelection();
		anchorNode.current = test?.anchorNode;
		focusNode.current = test?.focusNode;
		setAnchorOffset(test?.anchorOffset);
		setFocusOffset(test?.focusOffset);
	};

	return [selector];
};
