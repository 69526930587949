import { FC, useState } from 'react';
import { TagFormat, TagGroup } from 'widgets/ManualTagging/types';
import CustomSelectWithSearch from '../CustomSelectWithSearch/CustomSelectWithSearch';
import { createOptionsVEL } from 'widgets/ManualTagging/utils/utils';
import { TagsClassNamesEnum } from './types';
import s from './style.module.scss';
import { translate } from 'localizations';
import { NewTagComponent } from '..';
import EmptyTag from './EmptyTag';

interface AddingTagProps {
	tagsList: string[] | undefined;
	manualTag: TagFormat;
	isNewTag: boolean;
	index: number;
	language: string;
	isLoadingAddTag: boolean;
	handleAddTag: (e: TagFormat, index: number) => Promise<void>;
	handleValidate: (e: undefined | null | string, index: number) => boolean;
	deleteTag: ((id: string) => Promise<void>) | undefined;
	resetNewEmptyTag: (index: number) => void;
	accessRights: boolean | undefined;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
	isActiveTag: boolean; // флаг активного тега по клику
	tagGroup: TagGroup | null;
}

const AddingTag: FC<AddingTagProps> = ({
	index,
	tagsList,
	manualTag,
	isNewTag,
	language,
	handleAddTag,
	handleValidate,
	deleteTag,
	isLoadingAddTag,
	resetNewEmptyTag,
	accessRights,
	handleClickTagSettings,
	isActiveTag,
	tagGroup,
}) => {
	const hasTagFull = () => {
		return manualTag.id ? TagsClassNamesEnum.tagFull : TagsClassNamesEnum.tagGhost;
	};
	const [tagName, setTagName] = useState<TagFormat | null | undefined>(manualTag);
	const [classNameTag, setClassNameTag] = useState<TagsClassNamesEnum>(hasTagFull);

	const handleUpdateTagLocal = (e: TagFormat | null | undefined) => {
		setTagName(e);
	};

	// действие при выборе из списка
	const handleChangeName = async (e: TagFormat | null | undefined) => {
		handleUpdateTagLocal(e);

		if (e) {
			setClassNameTag(TagsClassNamesEnum.tagFull);
			await handleAddTag(e, index);
		} else {
			setClassNameTag(TagsClassNamesEnum.tagGhost);
			handleUpdateTagLocal(manualTag);
		}
	};

	// действие при изменении инпута
	const handleChangeInput = (e: TagFormat) => {
		handleUpdateTagLocal(e);
		if (e.value.trim().length) {
			setClassNameTag(TagsClassNamesEnum.tagGhostWithTitle);
		} else {
			setClassNameTag(TagsClassNamesEnum.tagGhost);
			handleUpdateTagLocal(manualTag);
		}
	};

	const isTagAdded = Boolean(manualTag.id);

	return (
		<div className={s.tagItem}>
			{isTagAdded ? (
				<NewTagComponent
					id={tagName?.id}
					name={tagName?.value}
					theme={tagName?.visible ? 'green' : 'gray'}
					value={null}
					cursor="pointer"
					settings
					tagGroup={tagGroup}
					language={language}
					accessRights={accessRights}
					deleteTag={deleteTag}
					isActiveTag={isActiveTag}
					handleClickTag={handleClickTagSettings}
				/>
			) : (
				<CustomSelectWithSearch
					index={index}
					testId="CustomSelectWithSearch"
					options={createOptionsVEL(tagsList)}
					value={tagName}
					Target={<EmptyTag tag={tagName} classNameTag={classNameTag} />}
					onChange={handleChangeName}
					handleChangeInput={handleChangeInput}
					isNewValue={isNewTag}
					handleValidation={handleValidate}
					placeholder={translate('newTagAdd', language)}
					language={language}
					onClickOutside={resetNewEmptyTag}
				/>
			)}
		</div>
	);
};

export default AddingTag;
