import { GptEngine, GPTOptionParams } from '../types';

export const defaultTemperature = 0;

export const defaultEngine: GptEngine = 'imotio_gpt';

export const defaultModel = 'gpt-3.5-turbo-16k';

export const defaultTemperatureParams: GPTOptionParams = {
	default: 0,
	maximum: 2,
	minimum: -2,
	title: 'Temperature',
	type: 'number',
};
