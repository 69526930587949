import { FC } from 'react';
import { InfoCircle } from 'shared/ui';

import cls from './styles.module.scss';

interface NoTableDataComponentProps {
	text: string;
	className?: string;
}

const NoTableDataComponent: FC<NoTableDataComponentProps> = ({ text, className }) => {
	return (
		<div className={`${className}? ${className} ${cls.root} : ${cls.root}`}>
			<div className={cls.icon}>
				<InfoCircle fill="#722ED1" />
			</div>
			<div className={cls.textBlock}>
				<span className={cls.firstLine}>{text}</span>
			</div>
		</div>
	);
};

export default NoTableDataComponent;
