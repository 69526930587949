import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { DisableMask, LabelSelect } from 'components/common';
import { translate } from 'localizations';
import { getCallAudio, getCallPublicToken, getExportParams } from 'store/calls/calls.slice';
import { getHash } from 'store/calls/actions';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { useAppSelector } from 'hooks/redux';
import { isDevStand } from 'utils/url';
import { CallInfoType, CallInitialStateType, CallSttType, ExportParams } from 'store/calls/calls.types';
import { ShareIcon } from 'components/Icons/ShareIcon';
import ActionsSelect from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/components/ActionsSelect/ActionsSelect';
import { selectCalls, selectFilterCallsHash } from 'store/calls/selectors';
import { UserType } from 'store/user/user.types';
import { useActionsFunctions } from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/hooks/useActionsFunctions';
import { DownloadSvg, RebootSvg, IconButton, ModalWindow } from 'shared/ui';
import { getFullCriteria } from '../../CallsSearch/Search';
import GPTIcon from '../../../../shared/ui/icons/general/GPTIcon/GPTIcon';
import CloudIcon from 'shared/ui/icons/general/CloudIcon/CloudIcon';
import { OptionType } from 'store/reports/reports.types';
import ExportModalInner from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/components/DownloadingSelect/components/ExportModalInner/ExportModalInner';
import { useToggleOpen } from 'hooks';

interface IOwnProps {
	callInfo: CallInfoType;
	currentCall: CallInitialStateType['currentCall'];
	language: string;
	isAuth: boolean | 'loading';
	userInfo: UserType | null;
	currentPage: number | undefined;
	setLang: (lang: { value: string; label: string } | null) => void;
	isHiddenTags: boolean;
	handleHiddenTags: () => void;
}

const CallBodyButtons: React.FC<IOwnProps> = ({
	callInfo,
	currentCall,
	language,
	isAuth,
	userInfo,
	currentPage,
	setLang,
	isHiddenTags,
	handleHiddenTags,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const allCriterias = useAppSelector((state) => state.search.allCriterias);
	const dispatch = useDispatch();
	const callId = callInfo.id.toUpperCase();
	const history = useHistory();
	const pathArray = history.location.pathname.split('/');
	const urlId = pathArray[2];

	const accessRights = useAppSelector((state) => state.user.accessRights);
	const host = window.origin;

	const callsData = useAppSelector(selectCalls);
	const pageCount = callsData?.found ?? null;

	const filterHash = useAppSelector(selectFilterCallsHash);

	const { onActionClick, onActionSTTClick } = useActionsFunctions(filterHash, callInfo.id);

	// onClick's действий со звонком

	async function formPublicToken(id: string) {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				type: 'info',
				text: translate('generateLink', language),
				time: 2000,
			}),
		);

		const publicTokenData = await dispatch(getCallPublicToken(id)); // @ts-ignore
		const publicToken: { access_token: string; token_type: string } = publicTokenData.payload;
		const devUrl = isDevStand();
		const baseUrl = devUrl || host;
		const hrefWithEnv = `${baseUrl}/${language}/${userInfo?.id}/call?id=${id}&token=${publicToken.access_token}&public=true`;
		await navigator.clipboard.writeText(hrefWithEnv);

		enqueueSnackbar(
			null,
			createSnackbarOptions({
				type: 'success',
				text: translate('publicLinkСopied', language),
				time: 2000,
			}),
		);
	}

	async function downloadAudio() {
		if (currentCall && currentCall.info) {
			const blobData = await dispatch(getCallAudio({ id: currentCall.id })); // @ts-ignore
			const blob = blobData.payload;
			if (blob) {
				const a = document.createElement('a');
				a.href = blob;
				a.download = `${currentCall.id}.opus`;
				document.body.appendChild(a);
				a.click();
				enqueueSnackbar(
					null,
					createSnackbarOptions({
						type: 'success',
						text: translate('callLoaded', language),
						time: 2000,
					}),
				);
			}
		}
	}

	const [downloadingLoading, setDownloadingLoading] = useState<boolean>(false);
	const { toggle: toggleExportModal, isShowing: exportModalIsShowing } = useToggleOpen();
	const [tags, setTags] = useState<ExportParams | null>(null);

	useEffect(() => {
		const fetchTags = async () => {
			try {
				const resultAction = await dispatch(getExportParams({ search_filter_hash: '', call_ids: [callId] }));
				const fetchedTags = (resultAction as any).payload as ExportParams;
				setTags(fetchedTags);
			} catch (error) {
				console.error('Error fetching tags:', error);
			}
		};

		exportModalIsShowing && fetchTags();
	}, [callId, exportModalIsShowing]);

	const valueHandler = () => {
		setDownloadingLoading(true);
		toggleExportModal();
		setDownloadingLoading(false);
	};

	// Скорее всего более не будет использоватся. Потому что делаем экспорт через onActionClick и слайс callsAction.
	// async function excelAction() {
	// 	if (currentCall && currentCall.id) {
	// 		const blobData = await dispatch(callAction({ id: currentCall.id, data: { action: 'stt_export' } })); // @ts-ignore
	// 		const blob = blobData.payload;
	// 		if (blob) {
	// 			const taskId = blob.task_id;
	// 			if (taskId) {
	// 				const taskData = await dispatch(getTaskStatus(taskId)); // @ts-ignore
	// 				const task = taskData.payload;
	// 				if (task) {
	// 					await dispatch(getActionFiles({ taskId, action: 'xlsx_export' }));
	// 					enqueueSnackbar(
	// 						null,
	// 						createSnackbarOptions({
	// 							type: 'success',
	// 							text: translate('exportExcelSuccess', language),
	// 							time: 2000,
	// 						}),
	// 					);
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	async function rebootAction() {
		if (currentCall && currentCall.stt) {
			onActionClick({ action: 'analyze' });
		}
	}

	async function historyAction() {
		const getFullCriteriaOutput = getFullCriteria('client_phone', allCriterias);
		if (getFullCriteriaOutput) {
			const clientPhoneCriteria = { ...getFullCriteriaOutput, values: [callInfo.clientPhone] };
			const hashData = await dispatch(getHash({ searchParams: [clientPhoneCriteria] })); // @ts-ignore
			const hash = hashData.payload;
			window.open(`${host}/${language}/${urlId}/calls?searchHash=${hash}`);
		}
	}

	const hasAccessToExport = accessRights && accessRights.export && accessRights.audio;
	const hasAccessToActions = accessRights && accessRights.calls_actions;
	const hasAccessToRetag = isAuth && accessRights && accessRights.retag;
	const hasAccessToGpt = accessRights && accessRights.gpt;

	const serviceLinks = callInfo?.serviceLinks;

	const serviceLinksOptions = serviceLinks?.map((item: any) => ({
		label: item.title,
		value: item.link,
	}));

	// Определяем цвет иконки
	const iconColor = (serviceLinksOptions?.length ?? 0) <= 0 ? '#D3D3D3' : '#738094';

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
				{/* Переход в источник коммуникации */}
				<LabelSelect
					notPlusMinus
					isSingle
					isDisable={(serviceLinksOptions?.length ?? 0) <= 0}
					closeMenuSelect
					valueHandler={async (value: OptionType) => {
						const valueResult = value.value;
						// Открываем ссылку в новой вкладке
						window.open(valueResult, '_blank', 'noopener,noreferrer');
					}}
					value={[]}
					options={serviceLinksOptions ?? []}
					customLabel={
						<IconButton
							icon={<CloudIcon color={iconColor} />}
							margin="0 9px 0 0"
							backgroundColor="#F8FAFC"
							tooltipTitle={`${translate('goingToSourceOfCommunication', language)}`}
							tooltipPlacement="top"
							padding="0"
						/>
					}
				/>

				{/* Правила GPT */}
				{hasAccessToGpt && (
					<Link to={`call/gpt?callId=${callInfo.id}`}>
						<IconButton
							icon={<GPTIcon />}
							margin="0 9px 0 0"
							backgroundColor="#F8FAFC"
							tooltipTitle={`${translate('applyGPTRule', language)}`}
							tooltipPlacement="top"
						/>
					</Link>
				)}
				{/* Перетегирование */}
				{hasAccessToRetag && (
					<IconButton
						margin="0 8px 0 0"
						backgroundColor="#F8FAFC"
						disabled={false}
						icon={<RebootSvg width="16" height="16" color="#738094" />}
						onClick={async () => {
							await rebootAction();
						}}
						tooltipTitle={`${translate('retagCall', language)}`}
						tooltipPlacement="top"
					/>
				)}

				{/* Скачать */}
				{hasAccessToExport &&
					(callInfo.communicationType !== 'chat' ? (
						<IconButton
							onClick={async () => {
								await downloadAudio();
							}}
							disabled={Boolean(currentCall && !accessRights.export && !accessRights.audio)}
							icon={<DownloadSvg width="16" height="16" color="#A3AEBE" />}
							margin="0 9px 0 0"
							backgroundColor="#F8FAFC"
							tooltipTitle={`${translate('download', language)}`}
							tooltipPlacement="top"
						/>
					) : null)}

				{/* Скачать excel */}
				{accessRights && accessRights.export && (
					<IconButton
						onClick={async () => {
							valueHandler();
						}}
						disabled={Boolean(currentCall && !accessRights.export)}
						icon={<div style={{ color: '#738094', fontWeight: '700' }}>EX</div>}
						margin="0 9px 0 0"
						backgroundColor="#F8FAFC"
						tooltipTitle={`${translate('exportExcel', language)}`}
						tooltipPlacement="top"
					/>
				)}

				{/* // TODO: будет скрыто, пока не разработан функционал сделок */}
				{/* История */}
				{/* {isAuth && ( */}
				{/* 	<IconButton */}
				{/* 		onClick={async () => { */}
				{/* 			await historyAction(); */}
				{/* 		}} */}
				{/* 		icon={<HistorySvg width="16" height="16" color="#738094" />} */}
				{/* 		backgroundColor="#F8FAFC" */}
				{/* 		margin="0 8px 0 0" */}
				{/* 		tooltipTitle={translate('history', language)} */}
				{/* 		tooltipPlacement="top" */}
				{/* 		disabled={!callInfo.clientPhone} */}
				{/* 	/> */}
				{/* )} */}

				{/* Публичная ссылка */}
				{isAuth && accessRights && accessRights.generate_public_link && (
					<IconButton
						onClick={async () => {
							await formPublicToken(callId);
						}}
						icon={<ShareIcon />}
						backgroundColor="#F8FAFC"
						tooltipTitle={translate('copyPublicUrl', language)}
						tooltipPlacement="top"
					/>
				)}

				{/* Другие действия внутри селекта */}
				<DisableMask disable={!hasAccessToActions}>
					<ActionsSelect
						accessRights={accessRights}
						language={language}
						pageCount={pageCount}
						onIconClick={onActionClick}
						onIconClickSTT={onActionSTTClick}
						isHiddenTags={isHiddenTags}
						onActionHideTags={handleHiddenTags}
						userInfo={userInfo}
						isSingleCall
						callId={callInfo.id}
						setLang={setLang}
					/>
				</DisableMask>
			</div>

			<ModalWindow
				isMWOpen={exportModalIsShowing}
				handleMWClose={toggleExportModal}
				text={translate('sttExportTitle', language)}
				width="600px"
				overflow="auto"
				overflowMW="auto"
			>
				<ExportModalInner
					engine={currentCall && (currentCall?.stt as CallSttType)?.engine}
					toggleSttExportModal={toggleExportModal}
					tags={tags}
					actionName="stt_export"
					onIconClick={onActionClick}
				/>
			</ModalWindow>
		</div>
	);
};

export default CallBodyButtons;
