// универсально можно удалять поля из json schema
export const parserSchemaToDelete = (schema: any, valuesToDelete: Set<string>) => {
	schema.required = schema.required?.filter((req: string) => !valuesToDelete.has(req));

	valuesToDelete.forEach((prop) => {
		if (!(prop in schema.properties)) return;
		if ('allOf' in schema.properties[prop]) {
			schema.properties[prop].allOf.forEach((propItem: any) => {
				delete schema.definitions[propItem.$ref.split('/').pop()];
			});
		} else if ('$ref' in schema.properties[prop]) {
			delete schema.definitions[schema.properties[prop].$ref.split('/').pop()];
		}
		delete schema.properties[prop];
	});
	if ('definitions' in schema && !Object.keys(schema.definitions).length) delete schema.definitions;
	if (!schema.required?.length) delete schema.required;
	return schema;
};

// задаем в схеме первоначальные значения
export const setDefaultValues = (schema: any, defaultValues: Record<string, string | number>) => {
	Object.keys(defaultValues).forEach((value) => {
		if (value in schema.properties) {
			schema.properties[value].default = defaultValues[value];
		}
	});
	return schema;
};

// нормализуем данные для формы
// в частности: библиотека ломает объект селекта, приводит его к неправильному ввиду { 0: "C", 1: "o", 2: "n", ..., label: "...", value: "..." }
// проверяем, есть ли в данных объект и приводим его к string, для отправки на сервер

export const normalizeFormData = (data: any): any => {
	if (Array.isArray(data)) {
		return data.map(normalizeFormData);
	} else if (typeof data === 'object' && data !== null) {
		// Если объект по типу { 0: "C", 1: "o", 2: "n", ..., label: "...", value: "..." }
		if (Object.keys(data).some((key) => !isNaN(Number(key)))) {
			return data.value ? data.value : null;
		}
		return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, normalizeFormData(value)]));
	}
	return data;
};
