import { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
import { UserType } from 'store/user/user.types';
import { useAppDispatch } from 'hooks/redux';
import { updateUser } from 'store/user/user.slice';
import { LangType } from 'store/lang/lang.slice';

import s from './styles.module.scss';
import { languages } from 'localizations/const';

interface LangSelectorProps {
	user: UserType | null;
	childUser: UserType | null;
	path: string;
	language: LangType;
	loggedUser: UserType | null;
}

const LangSelector: FC<LangSelectorProps> = (props) => {
	const { user, childUser, loggedUser, path, language } = props;
	const dispatch = useAppDispatch();
	const history = useHistory();

	async function handleLangChange(event: SelectChangeEvent) {
		if (loggedUser) {
			const currentLang = event.target.value as LangType;
			await dispatch(
				updateUser({
					id: loggedUser?.id,
					language: currentLang,
				}),
			);
			history.push(`/${currentLang}/${childUser ? childUser.id : user.id}/${path}`);
			window.location.reload();
		}
	}

	return (
		<Select
			MenuProps={{ disableScrollLock: true }}
			className={s.langHandler}
			variant="standard"
			value={language}
			onChange={handleLangChange}
		>
			{languages.map((lang) => (
				<MenuItem key={lang.value} className={s.langHandlerItem} value={lang.value}>
					{lang.label}
				</MenuItem>
			))}
		</Select>
	);
};

export default LangSelector;
