import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'api/api.config';
import dayjs from 'dayjs';
import { reportsSlice, searchSlice } from 'store';
import { PeriodsType } from 'store/reports/reports.types';
import { GetDateRangeByPeriodResponse } from 'store/search/namespaces/responses';

export const searchServiceAPI = createApi({
	reducerPath: 'searchServiceAPI',
	baseQuery: baseQueryWithReauth,
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		// получение дат по периоду
		// с последующей функцией
		getDateRangeByPeriod: build.query({
			query: ({ period, page }: { period: PeriodsType | null; page: 'calls' | 'reports' }) => ({
				url: `get_date_range_by_period?period=${period}`,
			}),
			transformResponse: (response: GetDateRangeByPeriodResponse) => {
				return [dayjs(response[0]), dayjs(response[1])];
			},
			async onQueryStarted({ period, page }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					page === 'calls' && dispatch(searchSlice.actions.setDate(data));
					page === 'reports' && dispatch(reportsSlice.actions.setDate(data));
				} catch (err) {}
			},
		}),
		// получение дат по периоду
		// обычный возврат дат
		getDateRangeByPeriodSimple: build.query({
			query: (period: PeriodsType | null) => ({
				url: `get_date_range_by_period?period=${period}`,
			}),
			transformResponse: (response: GetDateRangeByPeriodResponse) => {
				return [dayjs(response[0]), dayjs(response[1])];
			},
		}),
	}),
});
