import { FC } from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import { IconQuestion } from 'components/Icons/IconQuestion';
import Tooltip, { TooltipPropsType } from 'components/common/Tooltip/Tooltip';
import cls from './styles.module.scss';

export interface TitleWithTooltipProps {
	title: string;
	tooltipTitle?: string;
	theme: 'gray' | 'black';
	size: 'lg' | 'md' | 'mdL' | 'mds' | 'xs' | 'overall';
	rootClassName?: string;
	isRequired?: boolean;
	tooltipTheme?: 'dark' | 'white';
	placement?: TooltipPropsType['placement'];
	titleAfterQuestion?: string;
}

const TitleWithTooltip: FC<TitleWithTooltipProps> = ({
	title,
	tooltipTitle,
	theme,
	size,
	rootClassName,
	isRequired,
	tooltipTheme,
	placement,
	titleAfterQuestion,
}) => (
	<div className={classNames(cls.root, rootClassName)}>
		<Typography className={`${cls.text} ${cls[theme]} ${cls[size]}`}>{title}</Typography>
		{isRequired && <span>*</span>}

		{tooltipTitle && (
			<Tooltip title={tooltipTitle} placement={placement} background={tooltipTheme}>
				<div className={cls.questionWrapper}>
					<IconQuestion />
					{titleAfterQuestion && <div className={cls.titleAfterQuestion}>{titleAfterQuestion}</div>}
				</div>
			</Tooltip>
		)}
	</div>
);

export default TitleWithTooltip;
