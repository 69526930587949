export const LANGUAGES = ['ru', 'en', 'es', 'pt'];

export const DEFAULT_LANGUAGE = 'en';

export const languages = [
	{ value: 'ru', label: 'RU' },
	{ value: 'en', label: 'EN' },
	{ value: 'es', label: 'ES' },
	{ value: 'pt', label: 'PT' },
];
