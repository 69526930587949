export const getTime = (totalSeconds: number) => {
	const sign = Math.sign(totalSeconds);

	totalSeconds = Math.abs(totalSeconds);
	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const seconds = Math.floor(totalSeconds % 60);

	const convertHours = hours < 10 ? `0${hours}` : hours;
	const convertMinutes = minutes < 10 ? `0${minutes}` : minutes;
	const convertSeconds = seconds < 10 ? `0${seconds}` : seconds;

	const result =
		sign === -1
			? `-${convertHours}:${convertMinutes}:${convertSeconds}`
			: `${convertHours}:${convertMinutes}:${convertSeconds}`;

	return result;
};
