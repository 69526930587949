import React, { FC } from 'react';

const PhoneIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M2.58223 4.1959L4.4543 2.32559C4.68281 2.09707 4.98867 1.96875 5.31211 1.96875C5.63555 1.96875 5.94141 2.09531 6.16992 2.32559L8.18613 4.33828C8.41465 4.5668 8.54297 4.87441 8.54297 5.19785C8.54297 5.52305 8.41641 5.82715 8.18613 6.05742L6.61113 7.63418C6.96993 8.47017 7.48546 9.22974 8.12988 9.87187C8.775 10.5205 9.5291 11.032 10.3658 11.3941L11.9408 9.81738C12.1693 9.58887 12.4752 9.46055 12.7986 9.46055C12.9582 9.45996 13.1162 9.49121 13.2635 9.55249C13.4108 9.61376 13.5444 9.70382 13.6564 9.81738L15.6744 11.8301C15.9029 12.0586 16.0312 12.3662 16.0312 12.6896C16.0312 13.0148 15.9047 13.3189 15.6744 13.5492L13.8041 15.4195C13.4139 15.8098 12.876 16.033 12.324 16.033C12.2098 16.033 12.099 16.0242 11.9865 16.0049C9.65918 15.6217 7.34941 14.3824 5.48438 12.5191C3.62109 10.6523 2.38359 8.34258 1.99687 6.01348C1.88613 5.35254 2.10762 4.67227 2.58223 4.1959V4.1959ZM3.24316 5.8043C3.58594 7.87676 4.70039 9.94395 6.3791 11.6227C8.05781 13.3014 10.1232 14.4158 12.1957 14.7586C12.4559 14.8025 12.723 14.7146 12.9129 14.5266L14.7498 12.6896L12.8021 10.7402L10.6963 12.8496L10.6805 12.8654L10.3008 12.7248C9.14954 12.3015 8.10411 11.633 7.23693 10.7655C6.36975 9.89803 5.70158 8.85237 5.27871 7.70098L5.13809 7.32129L7.26152 5.19961L5.31387 3.2502L3.47695 5.08711C3.28711 5.27695 3.19922 5.54414 3.24316 5.8043Z"
			fill="#738094"
		/>
	</svg>
);

export default PhoneIcon;
