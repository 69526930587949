import { FC } from 'react';

interface DownloadSvgProps {
	props?: any;
}
const DeleteIcon: FC<DownloadSvgProps> = ({ props }) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.8125 3.375L15.1875 3.375C15.4982 3.375 15.75 3.62684 15.75 3.9375C15.75 4.24816 15.4982 4.5 15.1875 4.5L2.8125 4.5C2.50184 4.5 2.25 4.24816 2.25 3.9375C2.25 3.62684 2.50184 3.375 2.8125 3.375Z"
			fill="#F5222D"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.3125 6.75C7.62316 6.75 7.875 7.00184 7.875 7.3125V11.8125C7.875 12.1232 7.62316 12.375 7.3125 12.375C7.00184 12.375 6.75 12.1232 6.75 11.8125V7.3125C6.75 7.00184 7.00184 6.75 7.3125 6.75Z"
			fill="#F5222D"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.6875 6.75C10.9982 6.75 11.25 7.00184 11.25 7.3125V11.8125C11.25 12.1232 10.9982 12.375 10.6875 12.375C10.3768 12.375 10.125 12.1232 10.125 11.8125V7.3125C10.125 7.00184 10.3768 6.75 10.6875 6.75Z"
			fill="#F5222D"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.9375 3.375C4.24816 3.375 4.5 3.62684 4.5 3.9375V14.625H13.5V3.9375C13.5 3.62684 13.7518 3.375 14.0625 3.375C14.3732 3.375 14.625 3.62684 14.625 3.9375V14.625C14.625 14.9234 14.5065 15.2095 14.2955 15.4205C14.0845 15.6315 13.7984 15.75 13.5 15.75H4.5C4.20163 15.75 3.91548 15.6315 3.7045 15.4205C3.49353 15.2095 3.375 14.9234 3.375 14.625V3.9375C3.375 3.62684 3.62684 3.375 3.9375 3.375Z"
			fill="#F5222D"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.11926 1.61926C6.43573 1.30279 6.86495 1.125 7.3125 1.125H10.6875C11.1351 1.125 11.5643 1.30279 11.8807 1.61926C12.1972 1.93572 12.375 2.36495 12.375 2.8125V3.9375C12.375 4.24816 12.1232 4.5 11.8125 4.5C11.5018 4.5 11.25 4.24816 11.25 3.9375V2.8125C11.25 2.66332 11.1907 2.52024 11.0852 2.41475C10.9798 2.30926 10.8367 2.25 10.6875 2.25H7.3125C7.16332 2.25 7.02024 2.30926 6.91475 2.41475C6.80926 2.52024 6.75 2.66332 6.75 2.8125V3.9375C6.75 4.24816 6.49816 4.5 6.1875 4.5C5.87684 4.5 5.625 4.24816 5.625 3.9375V2.8125C5.625 2.36495 5.80279 1.93572 6.11926 1.61926Z"
			fill="#F5222D"
		/>
	</svg>
);

export default DeleteIcon;
