import { FC } from 'react';
import { Upload, UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { InfoCircle } from 'shared/ui';
import { translate } from 'localizations';
import s from './styles.module.scss';

interface UploadFilesProps {
	config: Record<string, any>;
	lang: string;
	props: UploadProps;
}

const UploadFiles: FC<UploadFilesProps> = ({ config, lang, props }) => {
	const { Dragger } = Upload;

	return (
		<div className={s.root}>
			<div className={s.upload}>
				<Dragger {...props}>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					{config?.text && <p className="ant-upload-text">{config?.text}</p>}
					{config?.textHint && <p className="ant-upload-hint">{config?.textHint}</p>}
				</Dragger>
			</div>
			{config?.requirements && (
				<div className={s.requirements}>
					<div className={s.requirementsTitle}>
						<InfoCircle fill="#FAAD14" />
						<p>{translate('uploadRequirements', lang)}</p>
					</div>

					<div className={s.requirementsContent}>
						{config?.acceptedFilesText && (
							<div className={s.requirementsItem}>
								<p>
									{translate('uploadFormat', lang)}
									{':'}&nbsp;
								</p>
								{config?.acceptedFilesText.join(', ')}
							</div>
						)}
						{config?.maxSizeText && (
							<div className={s.requirementsItem}>
								<p>
									{translate('uploadSize', lang)}
									{':'}&nbsp;
								</p>
								{config?.maxSizeText}
							</div>
						)}
						{config?.maxCountText && (
							<div className={s.requirementsItem}>
								<p>
									{translate('uploadCount', lang)}
									{':'}&nbsp;
								</p>
								{config?.maxCountText}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default UploadFiles;
