import { FC } from 'react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LicenseInfo } from '@mui/x-license';
import { setupStore } from 'store/store';
import theme from './theme';
import Routes from './Routes';
import CalliFrame from './pages/CalliFrame/CalliFrame';
import { AppInitializer } from 'AppInitializer';

const store = setupStore();

const App: FC = () => {
	// ключ таблицы mui
	// @ts-ignore
	LicenseInfo.setLicenseKey(process.env.REACT_APP_KEY_MUI_DATA_GRID_PRO);

	// Отдельная логика call-widget
	if (window.location.pathname.includes('/call-widget')) {
		return (
			<StylesProvider injectFirst>
				<ThemeProvider theme={theme}>
					<SnackbarProvider maxSnack={10}>
						<Provider store={store}>
							<BrowserRouter basename={process.env.REACT_APP_SUB_URL_PATH}>
								<Switch>
									<Route path="/call-widget" component={CalliFrame} exact />
								</Switch>
							</BrowserRouter>
						</Provider>
					</SnackbarProvider>
				</ThemeProvider>
			</StylesProvider>
		);
	}

	// Обычный «режим» приложения:
	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={10}>
					<Provider store={store}>
						<BrowserRouter basename={process.env.REACT_APP_SUB_URL_PATH}>
							{/* 
                Оборачиваем всё в AppInitializer. 
                Пока он не закончит, мы показываем лоадер, 
                а когда закончит – рендерим <Routes />. 
              */}
							<AppInitializer>
								<Routes />
							</AppInitializer>
						</BrowserRouter>
					</Provider>
				</SnackbarProvider>
			</ThemeProvider>
		</StylesProvider>
	);
};

export default App;
