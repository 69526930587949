import { getFormattedTags } from 'pages/Calls/CallBody/utils';
import { callTagsLimit } from 'pages/Calls/constants';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callsActions } from 'store/calls';
import { CallTagType } from 'store/calls/calls.types';
import { RootState } from 'store/store';
import { getGlobalStyles } from 'styles';
import { translate } from 'localizations';
import { Skeleton } from '@mui/material';
import { NewTagComponent } from 'shared/ui';
import { TagGroup } from 'widgets/ManualTagging/types';
import s from '../../styles.module.scss';
import { TagsWithCount } from 'pages/Calls/types';

interface IOwnProps {
	language: string;
	dealTags: TagsWithCount[];
	callId: string | undefined;
	hasCallInfo: boolean;
	solo: boolean | undefined;
	expanded: boolean;
	accessRights: boolean | undefined;
	deleteTag?: (id: string) => Promise<void>;
	tagGroup: TagGroup | null;
	clickedTagId: string | null;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
	isHiddenTags: boolean;
}

const DealTags: FC<IOwnProps> = ({
	dealTags,
	language,
	hasCallInfo,
	callId,
	solo,
	expanded,
	accessRights,
	deleteTag,
	tagGroup,
	clickedTagId,
	handleClickTagSettings,
	isHiddenTags,
}) => {
	const dispatch = useDispatch();
	const { setDealTagExpanded } = callsActions;
	const { grayTag } = getGlobalStyles();
	const { trimmedTags, reducedTags } = getFormattedTags(dealTags, callTagsLimit);

	const expandedDealTags = useSelector((state: RootState) => state.calls.expandedDealTags);
	const showAllTags = callId ? expandedDealTags?.[callId] || false : false;

	const tagsToDisplay = showAllTags ? dealTags : trimmedTags;

	const handleShowMoreTags = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (callId) {
			dispatch(setDealTagExpanded({ callId, isExpanded: !showAllTags }));
		}
	};

	const stopPropagation = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};

	return (
		<div className={s.container} onClick={stopPropagation}>
			{dealTags?.length > 0 ? (
				tagsToDisplay
					.filter((tag) => solo || expanded || tag.tag.visible)
					.map((tag: { tag: CallTagType; count: number }, index) => {
						if (isHiddenTags && (solo || expanded) && !tag.tag.visible) return null;
						const dealTag = tag?.tag;
						return (
							<div className={s.tagContainer} key={`${dealTag.value}-${index}`}>
								<NewTagComponent
									id={dealTag?.id}
									name={dealTag.name}
									value={dealTag?.value}
									theme={!dealTag.visible ? 'gray' : 'lightBlue'}
									language={language}
									accessRights={accessRights}
									count={tag?.count}
									// settings={(solo || expanded) && true}
									settings={false}
									isActiveTag={clickedTagId === dealTag.id && tagGroup === 'deal'}
									handleClickTag={handleClickTagSettings}
									tagGroup="deal"
								/>
							</div>
						);
					})
			) : hasCallInfo ? (
				translate('noTags', language)
			) : (
				<div className={s.skeletonContainer}>
					<Skeleton className={s.skeleton} height={20} variant="text" />
					<Skeleton className={`${s.skeleton} ${s.skeletonWithMarginTop}`} height={20} variant="text" />
				</div>
			)}
			{reducedTags?.length > 0 && (
				<button className={grayTag} onClick={handleShowMoreTags}>
					{showAllTags
						? translate('tagHide', language)
						: `${translate('show_more', language)} (${dealTags?.length - callTagsLimit})`}
				</button>
			)}
		</div>
	);
};

export default DealTags;
