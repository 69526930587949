import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
	getUserFilter,
	getUserToken,
	getUsersWithoutChildren,
	getMeAccessRights,
	getMe,
	getUsersWithChildren,
} from 'store/user/user.slice';
import { langSlice, userSlice } from 'store';
import { LANGUAGES } from 'localizations/const';
import { getTokens, getTokenWithAuthCheck } from 'utils/tokens';
import { EnvsManager } from './utils/enviroments';
import mixpanel from 'mixpanel-browser';
import { AccessRights, UserType } from './store/user/user.types';
import { WithMainLoader } from 'containers';

export const AppInitializer: FC = ({ children }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	// Флаг завершения инициализации приложения
	const [initialized, setInitialized] = useState(false);

	// Состояние авторизации и данные текущего пользователя из Redux
	const isAuth = useAppSelector((state) => state.user.isAuth);
	const { loggedUser } = useAppSelector((state) => state.user);

	// 1. Извлечение токена авторизации из URL до основной инициализации
	useEffect(() => {
		const authByUrlToken = async () => {
			const parsedSearch = queryString.parse(history.location.search);
			const open_id = history.location.pathname.split('/').includes('open_id');

			// Если присутствует open_id и токен, сохраняются токены в localStorage и sessionStorage
			if (open_id && parsedSearch.token) {
				await localStorage.setItem('localToken', JSON.stringify({ localToken: parsedSearch.token }));
				await sessionStorage.setItem('sessionToken', JSON.stringify({ sessionToken: parsedSearch.token }));
			}

			// Если присутствует authToken, сохраняем его в sessionStorage
			if (parsedSearch.authToken) {
				await sessionStorage.setItem('sessionToken', JSON.stringify({ sessionToken: parsedSearch.authToken }));
			}
		};
		authByUrlToken().then();
	}, [history]);

	// 2. Основная инициализация приложения
	useEffect(() => {
		async function init() {
			// Инициализация mixpanel, если не в режиме разработки и не на ppr.imot.io
			if (!EnvsManager.isDev && !window.location.host.includes('ppr.imot.io')) {
				mixpanel.init('8bfe149c16deed5afa6a01e5c4b9ebae', {
					debug: false,
					track_pageview: true,
					persistence: 'localStorage',
				});
			}

			// Разбиение URL на части для дальнейшей обработки
			const pathArray = history.location.pathname.split('/');
			const search = queryString.parse(history.location.search);
			// Проверка флагов перезагрузки из sessionStorage
			const hasReloaded = sessionStorage.getItem('hasReloaded');
			const hasCallsReloaded = sessionStorage.getItem('hasCallsReloaded');

			// Проверяем сохраненного childUser из sessionStorage
			const savedChildUserId = sessionStorage.getItem('selectedChildUserId');

			// Если в URL передан язык и авторизация завершена, устанавливается язык в Redux
			if (LANGUAGES.includes(pathArray[1]) && isAuth && isAuth !== 'loading') {
				dispatch(langSlice.actions.setLang(pathArray[1]));
			}

			// Извлечение токенов для авторизации
			const { adminToken } = getTokens();
			const token = getTokenWithAuthCheck();

			// Обработка флага isAmoWidget; если он присутствует, то авторизуемся
			if (search.isAmoWidget) {
				dispatch(userSlice.actions.setAuth(true));
				window.sessionStorage.setItem('isAmoWidget', 'true');
				if (!hasReloaded) {
					sessionStorage.setItem('hasReloaded', 'true');
					window.location.reload();
					return;
				}
			}

			// Дополнительная обработка для isAmoWidget, если URL содержит сегмент 'calls'
			if (search.isAmoWidget && pathArray.includes('calls')) {
				dispatch(userSlice.actions.setAuth(true));
				history.push(`/${pathArray[1]}/${pathArray[2]}/calls`);
				if (!hasCallsReloaded) {
					sessionStorage.setItem('hasCallsReloaded', 'true');
					window.location.reload();
					return;
				}
			}

			// Обновление sessionStorage с текущим токеном
			window.sessionStorage.setItem('sessionToken', JSON.stringify({ sessionToken: token }));

			// Получение данных пользователя с использованием токена (админ или обычного)
			const userData = await dispatch(getUserFilter({ sessionToken: adminToken || token }));
			const user = userData?.payload as UserType;

			const tokenData = await dispatch(getUserToken(user.id));

			// Получение прав доступа пользователя и их сохранение в Redux
			const accessRightsResp = await dispatch(getMeAccessRights());
			if (accessRightsResp) {
				dispatch(userSlice.actions.setAccessRights(accessRightsResp.payload as AccessRights));
			}

			// Если токен действительный и пользователь получен без ошибок
			if (token && !user?.error) {
				// Если в URL указан конкретный пользователь (child id) или есть сохраненный, переключаемся на него
				const isCustomRoute =
					(pathArray[2] && pathArray[2] !== '_' && pathArray[2] !== user.id) || savedChildUserId;
				if (isCustomRoute) {
					const childIdToLoad = pathArray[2] && pathArray[2] !== '_' ? pathArray[2] : savedChildUserId;
					const localToken: string = tokenData.payload?.access_token;

					if (localToken && childIdToLoad) {
						const childUserData = await dispatch(getUserFilter({ sessionToken: localToken }));
						const childUser = childUserData.payload as UserType;
						if (childUser) {
							dispatch(userSlice.actions.setCurrentUser(childUser));
							dispatch(userSlice.actions.setChildUser(childUser));
							window.sessionStorage.setItem('sessionToken', JSON.stringify({ sessionToken: localToken }));
							sessionStorage.setItem('selectedChildUserId', childUser.id); // Сохраняем выбранного пользователя
						}
					}
				}

				// Установка текущего пользователя в Redux
				dispatch(userSlice.actions.setCurrentUser(user));

				// Идентификация и установка данных в mixpanel, если не в режиме разработки
				if (!EnvsManager.isDev && !window.location.host.includes('ppr.imot.io')) {
					mixpanel.identify(loggedUser?.id);
					mixpanel.people.set({
						$name: loggedUser?.name,
						$email: loggedUser?.email,
						$role: loggedUser?.role,
						$timezone: loggedUser?.timezone,
						$language: loggedUser?.language,
					});
				}

				// Если adminToken отсутствует, но роль админа или менеджера, сохраняем adminToken в localStorage
				if (!adminToken && ['admin', 'manager'].includes(user?.role)) {
					await localStorage.setItem('adminToken', JSON.stringify({ adminToken: token }));
				}

				// Если adminToken присутствует и в URL указан child id или есть сохраненный, получаем данные дочернего пользователя
				if (adminToken && ((pathArray[2] && pathArray[2] !== '_') || savedChildUserId)) {
					const childIdToLoad = pathArray[2] && pathArray[2] !== '_' ? pathArray[2] : savedChildUserId;
					const tokenData = await dispatch(getUserToken(childIdToLoad));
					const tokenDataPayload = tokenData.payload;
					if (tokenDataPayload) {
						const localToken: string = tokenDataPayload.access_token;
						const childUserData = await dispatch(getUserFilter({ sessionToken: localToken }));
						const childUser = childUserData.payload as UserType;
						if (childUser) {
							dispatch(userSlice.actions.setChildUser(childUser));
							sessionStorage.setItem('selectedChildUserId', childUser.id); // Сохраняем выбранного пользователя
						}
					}
				}

				// Задаем язык приложения для залогиненного пользователя
				const meData = await dispatch(getMe({ token: adminToken || token }));
				const language = meData?.payload?.language;
				dispatch(langSlice.actions.setLang(language));

				// Получение списка дочерних пользователей и, если условий достаточно, переключение на конкретного пользователя
				const childUsersData: any = await dispatch(getUsersWithChildren());
				const managerUsers = childUsersData?.payload?.filter((usr: UserType) => usr.role === 'user');
				const currentPath = history.location.pathname;
				const authPage =
					currentPath === '/' ||
					currentPath === `/${language}/` ||
					currentPath === `/${language}` ||
					currentPath.split('/').includes('auth');

				if (!user?.accessRights?.includes('add_user') && managerUsers?.length === 1) {
					await dispatch(userSlice.actions.setChildUser(managerUsers[0]));
					const tokenData = await dispatch(getUserToken(managerUsers[0].id));

					if (tokenData?.payload?.access_token) {
						window.sessionStorage.setItem(
							'sessionToken',
							JSON.stringify({ sessionToken: tokenData.payload.access_token }),
						);
						sessionStorage.setItem('selectedChildUserId', managerUsers[0].id); // Сохраняем выбранного пользователя
					}
					if (authPage) {
						history.replace(`/${user?.language}/${managerUsers[0]?.id}/calls`);
					}
				} else {
					if (authPage) {
						history.replace(`/${user.language}/${user.id}/calls`);
					}
				}
				// Установка статуса авторизации в Redux
				dispatch(userSlice.actions.setAuth(true));
			} else {
				// Если произошла ошибка авторизации, сохраняется URL для редиректа и выполняется разлогинивание
				sessionStorage.setItem('redirectAfterLogin', history.location.pathname + history.location.search);
				localStorage.clear();
				sessionStorage.removeItem('selectedChildUserId'); // Очищаем при выходе
				dispatch(userSlice.actions.setAuth(false));
			}

			// Завершение инициализации приложения
			setInitialized(true);

			// Если имеется сохранённый URL для редиректа, выполнить редирект по нему и очистить значение
			const storedRedirectUrl = sessionStorage.getItem('redirectAfterLogin');
			if (storedRedirectUrl) {
				sessionStorage.removeItem('redirectAfterLogin');
				history.replace(storedRedirectUrl);
				return;
			}
		}

		// Запуск инициализации, если статус авторизации "loading", иначе установка флага инициализации
		if (isAuth === 'loading') {
			init();
		} else if (isAuth === false) {
			setInitialized(true);
		}
	}, [isAuth]);

	// Если инициализация не завершена и авторизация не false, отображается лоадер
	if (!initialized && isAuth !== false) {
		return <WithMainLoader />;
	}

	// После инициализации отрисовываются дочерние компоненты
	return <>{children}</>;
};
