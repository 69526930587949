import { WidgetProps } from '@rjsf/utils';
import STTSelect from 'widgets/STT/components/STTSelect/STTSelect';

const CustomSelectWidget = (props: WidgetProps) => {
	const { options } = props;
	let { enumOptions } = options;

	const processedValue =
		typeof props.value === 'object' && props.value !== null
			? props.value
			: enumOptions?.find((opt) => opt.value === props.value) || enumOptions?.[0];

	return (
		<div style={{ marginBottom: '16px' }}>
			<STTSelect
				{...props}
				options={enumOptions}
				onSelectionChange={(e) => {
					props.onChange(e);
				}}
				testId={props.name}
				title={props.label}
				value={processedValue}
				isDisabled={false}
				errors={undefined}
				placeholder={props.name}
				width="250px"
			/>
		</div>
	);
};

export default CustomSelectWidget;
