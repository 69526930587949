import { FC, useCallback, useState } from 'react';
import AddingTag from 'shared/ui/AddingTag/AddingTag';
import { TagFormat, TagGroup } from '../types';
import s from './styles.module.scss';

interface ManualTaggingBodyProps {
	tags: TagFormat[]; // новые созданные теги из кнопки плюса
	tagsList: string[] | undefined; // список ручных тегов в выпадашке
	language: string;
	isLoadingAddTag: boolean; // загрузка создания тега
	handleAddTag: (e: TagFormat, index: number) => Promise<void>; // добавление тега
	handleValidate: (e: undefined | null | string, index: number) => boolean;
	accessRights: boolean | undefined;
	deleteTag: ((id: string) => Promise<void>) | undefined;
	resetNewEmptyTag: (index: number) => void;
	tagGroup: TagGroup | null;
	clickedTagId: string | null;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
	isHiddenTags: boolean;
}

const ManualTaggingBody: FC<ManualTaggingBodyProps> = ({
	tags,
	tagsList,
	language,
	isLoadingAddTag,
	handleAddTag,
	handleValidate,
	accessRights,
	deleteTag,
	resetNewEmptyTag,
	tagGroup,
	clickedTagId,
	handleClickTagSettings,
	isHiddenTags,
}) => {
	return (
		<div className={s.tagsContainer}>
			{tags.map((tag, index) => {
				const isNewTag = !tag.id;
				if (isHiddenTags && !isNewTag && !tag.visible) return null;
				return (
					<AddingTag
						index={index}
						tagsList={tagsList}
						manualTag={tag}
						language={language}
						key={`${tag.value}-${index}`}
						isNewTag={isNewTag}
						tagGroup="manual"
						handleAddTag={handleAddTag}
						isLoadingAddTag={isLoadingAddTag}
						handleValidate={handleValidate}
						accessRights={accessRights}
						deleteTag={deleteTag}
						resetNewEmptyTag={resetNewEmptyTag}
						isActiveTag={clickedTagId === tag?.id && tagGroup === 'manual'}
						handleClickTagSettings={handleClickTagSettings}
					/>
				);
			})}
		</div>
	);
};

export default ManualTaggingBody;
