import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { useSnackbar } from 'notistack';
import { SttValuesForRequest } from 'widgets/STT/namespaces';
import { getTaskStatus, tasksSlice } from 'store/tasks/tasks.slice';
import { callAction, callApplyGpt, getCallInfo } from 'store/calls/actions';
import { callsAction, getCallStt } from 'store/calls/calls.slice';
import { translate } from 'localizations';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';
import { callsActions } from 'store/calls';
import { checkListsActions } from 'store/checkLists';
import { getCheckListsByCallId } from 'store/checkLists/actions';

import { selectCalls } from 'store/calls/selectors';
import { CallInfoType, ResponseBaseCallsDataType } from 'store/calls/calls.types';
import { exportsActions } from 'pages/Calls/components/CallsHeader/const';
import { useParametersForAction } from './useParametersForAction';

export interface OnActionClickOptions {
	action: string;
	columns?: string[];
	stereo?: boolean;
	stt?: SttValuesForRequest;
	engine?: string | null | undefined | boolean;
}

export const useActionsFunctions = (filterHash: string | undefined | null, singleCallId: string | null = null) => {
	const dispatch = useAppDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { setParameters, setParametersSingleCall } = useParametersForAction();
	const { language } = useAppSelector((state: RootState) => state.lang);
	const callsData = useAppSelector(selectCalls);
	const callsInfo = callsData?.calls;
	const mode = useAppSelector((state) => state.search.calls.mode);

	const setWarningSnackbar = () => {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				text: translate('actionIsNotAvailable', language),
				type: 'warning',
				time: 2000,
			}),
		);
	};
	const setActionStartSnackbar = () => {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				type: 'info',
				text: translate('actionStart', language),
				time: 2000,
			}),
		);
	};
	const setSuccessSnackbar = (keyTranslate: string) => {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				type: 'success',
				text: translate(keyTranslate, language),
				time: 2000,
			}),
		);
	};

	const updateCall = async (callId: string) => {
		dispatch(callsActions.setCallLoading(true));
		dispatch(checkListsActions.setCallsCheckListsLoading(true));
		const newCallInfoData = await dispatch(getCallInfo({ id: callId })); // @ts-ignore
		const newCallInfo = newCallInfoData.payload;
		dispatch(callsActions.replaceCallInfoInArray(newCallInfo));
		dispatch(callsActions.setInfo(newCallInfo));
		dispatch(getCallStt({ id: callId }));
		dispatch(callsActions.setCallLoading(false));
		dispatch(getCheckListsByCallId(callId));
	};

	const resetCallsHash = () => {
		// очищаем хэш поиска звонков, тк список звонков может меняться после каких-то действий
		dispatch(callsActions.setSearchCallsHash(undefined));
	};

	const setRealtimeTask = async (taskId: string, action: string | null = null) => {
		// нам не надо перезапрашивать звонки у вызова для одного звонка (кроме удаления)
		const taskData = await dispatch(getTaskStatus(taskId)); // @ts-ignore
		const task = taskData.payload;
		if (action) {
			dispatch(tasksSlice.actions.addRealtimeTask({ ...task, action, startProgress: false }));
		} else {
			dispatch(tasksSlice.actions.addRealtimeTask({ ...task, startProgress: false }));
		}
		dispatch(tasksSlice.actions.setNewTaskMessage(true));
	};

	const deleteOneCall = (callsArray: (CallInfoType | null)[] | null | undefined, callId: string | null) => {
		if (callsArray && callsArray.length > 0) {
			const filteredCalls = callsArray.filter((call) => call?.id !== callId);
			return filteredCalls;
		}
		return callsArray;
	};

	const deleteOneId = (callsIds: string[], callId: string | null) =>
		callsIds.filter((id) => id.toLowerCase() !== callId);

	const decreaseTotalAndFoundCalls = (callsDataInfo: ResponseBaseCallsDataType | null) => {
		if (callsDataInfo) {
			let found = callsDataInfo?.found;
			let total = callsDataInfo?.total;
			const filteredCalls = deleteOneCall(callsInfo, singleCallId);
			const filteredIds = deleteOneId(callsDataInfo.call_ids, singleCallId);

			const newCallsDataInfo = {
				...callsDataInfo,
				found: --found,
				total: --total,
				calls: filteredCalls,
				call_ids: filteredIds,
			};
			return newCallsDataInfo;
		}
		return callsDataInfo;
	};

	const callActionDeleteHandler = async (callActionData: any) => {
		const actionData = await dispatch(callAction(callActionData));

		if (actionData.meta.requestStatus === 'fulfilled') {
			dispatch(callsActions.setCurrentCall(false));
			dispatch(callsActions.setExpanded({ id: null }));

			const decreasedNumbers = decreaseTotalAndFoundCalls(callsData);
			dispatch(callsActions.setBaseCallsData({ ...decreasedNumbers }));

			resetCallsHash();
			setSuccessSnackbar('callDeleted');
		} else {
			setWarningSnackbar();
		}
	};

	const callActionHandler = async (callActionData: any) => {
		setActionStartSnackbar();
		let actionData;

		if (callActionData?.data?.action === 'apply_gpt') {
			actionData = await dispatch(callApplyGpt(callActionData));
		} else {
			actionData = await dispatch(callAction(callActionData));
		}

		if ((actionData.payload as any)?.response?.status === 402) {
			enqueueSnackbar(
				null,
				createSnackbarOptions({
					time: 3000,
					type: 'error',
					text: translate('error402', language),
				}),
			);
		}

		// @ts-ignore
		const taskIdData: { task_id: string } | undefined = actionData?.payload;
		const taskId = taskIdData?.task_id; // @ts-ignore
		const successAction = actionData?.meta?.requestStatus === 'fulfilled';

		if (taskId) {
			// resetCallsHash();
			setRealtimeTask(taskId, callActionData?.data?.action);
		} else if (successAction) {
			// обновлять звонок во всех действиях, кроме "применить информирование"
			if (singleCallId && callActionData?.data?.action !== 'apply_notify_rules') {
				// resetCallsHash();
				updateCall(singleCallId);
			}
			setSuccessSnackbar('actionDone');
		} else {
			setWarningSnackbar();
		}
	};

	const callsActionHandler = async (callsActionData: any) => {
		setActionStartSnackbar();
		const actionData = await dispatch(callsAction(callsActionData));

		if (actionData?.payload?.response?.status === 402) {
			enqueueSnackbar(
				null,
				createSnackbarOptions({
					time: 3000,
					type: 'error',
					text: translate('error402', language),
				}),
			);
		}

		const taskIdData: { task_id: string } | undefined = actionData.payload;
		const taskId = taskIdData?.task_id;
		if (taskId) {
			resetCallsHash();
			if (exportsActions.includes(callsActionData.action)) {
				setRealtimeTask(taskId, callsActionData.action);
			} else {
				setRealtimeTask(taskId);
			}
		} else {
			setWarningSnackbar();
		}
	};

	const onActionClick = async ({ action, columns, stereo, stt, engine }: OnActionClickOptions) => {
		if (singleCallId) {
			if (action === 'delete') {
				const dataIdForCallAction = { id: singleCallId, data: setParameters({ action }) };
				await callActionDeleteHandler(dataIdForCallAction);
				return;
			}

			const dataAndIdForCallAction = engine
				? setParametersSingleCall({
						action,
						id: singleCallId,
						columns,
						stereo,
						stt,
						engine,
				  })
				: setParametersSingleCall({
						action,
						id: singleCallId,
						columns,
						stereo,
						stt,
				  });

			await callActionHandler(dataAndIdForCallAction);
		} else {
			await callsActionHandler(setParameters({ action, filterHash, columns, stt, stereo }));
		}
	};

	const onActionSTTClick = (values: SttValuesForRequest) => {
		onActionClick({ action: 'stt', stt: values });
	};

	return { onActionClick, onActionSTTClick, updateCall };
};
